import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import {
  Card,
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../../assets/components/components'
import images from '../../../../../assets/images'
import { colors } from '../../../../../assets/styles/styles'
import ModalFormTemplate from '../../../../../components/Modal/ModalFormTemplate'
import FetchHandler from '../../../../../components/State/FetchHandler'
import List from '../../../../Home/Voucher/components/List'
import ModalDetails from '../../../../Home/Voucher/components/ModalDetails'
import api from '../../api'

const ModalVoucher = ({
  open,
  close,
  active,
  setActive,
  digital = false,
  details,
}) => {
  const [errordata, setErrordata] = useState(false)
  const [data, setData] = useState([])

  const [modalDetails, setModalDetails] = useState(false)

  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, fetch] = useAsyncFn(async (digital, token) => {
    setErrordata(false)
    const res = await api.getMyVoucher(digital ? 2 : 1, token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let data = []
    let owned = res?.data?.ownedvoucher || []
    let free = res?.data?.freevoucher || []

    owned = owned.map((g) => {
      let obj = {}
      obj = { ...g.voucher, owner_id: g.id, end_period: g.expire_time }
      return obj
    })

    data = [...owned, ...free]
    setData(data)
  }, [])

  const refetch = () => {
    fetch(digital, token)
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      <ModalFormTemplate
        open={open}
        close={close}
        title="Pilih Voucher"
        content={
          <>
            {active?.id ? (
              <>
                <Text margin="0 0 5px">Voucher Anda Saat Ini</Text>
                <Card padding="0">
                  <Flex>
                    <FlexCell
                      padding="15px 20px"
                      style={{ borderRight: '1px dashed' + colors.primary }}
                    >
                      <Image src={images.icons.voucher} width="25px" />
                    </FlexCell>
                    <FlexCell padding="15px" flex={1}>
                      <Flex justifyContent="space-between">
                        <Text fontWeight="bold" fontSize="16px">
                          {active?.name}
                        </Text>

                        <Text
                          cursor="pointer"
                          fontSize="14px"
                          color={colors.secondary}
                          onClick={() => setActive('')}
                        >
                          Hapus
                        </Text>
                      </Flex>
                    </FlexCell>
                  </Flex>
                </Card>
              </>
            ) : null}

            <FetchHandler
              loading={state.loading}
              error={errordata}
              refetch={refetch}
              showEmpty={data?.length == 0}
              content={
                <>
                  {active?.id ? <Text>Pilih voucher lainnya</Text> : null}

                  {data?.map((g, i) => {
                    return (
                      <List
                        key={i}
                        data={g}
                        // onClick={() => {
                        //   setActive(g)
                        //   close()
                        // }}
                        onClick={() => setModalDetails(g)}
                      />
                    )
                  })}
                </>
              }
            />
          </>
        }
      />

      {modalDetails != '' && (
        <ModalDetails
          open={modalDetails != ''}
          close={() => setModalDetails('')}
          digital={digital}
          apply={setActive}
          data={modalDetails}
          usable
          details={details}
        />
      )}
    </>
  )
}

export default ModalVoucher
