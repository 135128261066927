import { baseURL } from '../../utils/apihost'
import { fetchPost } from '../../utils/fetch'

export default {
  async findNearbyAddress(current_lat, current_lng, token) {
    let form = JSON.stringify({
      current_lat,
      current_lng,
    })
    let responseJSON = await fetchPost(
      baseURL + '/address/getNearestAddress',
      form,
      token,
    )
    return responseJSON
  },
}
