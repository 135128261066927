const url_instagram = 'https://www.instagram.com/alfamidi_ku/'
const url_twitter = 'https://twitter.com/alfamidi_ku'
const url_facebook = 'https://www.facebook.com/alfamidiku/'
const url_youtube = 'https://www.youtube.com/channel/UC4fbC31FGEHBbV6SERScRTg'

const genders = [
  {
    name: 'Laki-laki',
    value: 'Laki-laki',
  },
  {
    name: 'Perempuan',
    value: 'Perempuan',
  },
]

const locationDelta = {
  latitudeDelta: 0.0922,
  longitudeDelta: 0.0421,
}

const filterProductType = [
  {
    name: 'Paling Sesuai',
    sort_key: -1,
    SortBy: '',
    sort_by_price: '',
  },
  {
    name: 'Alphabet (A-Z)',
    sort_key: 0,
    SortBy: 'AlphabetAsc',
    sort_by_price: '',
  },
  {
    name: 'Alphabet (Z-A)',
    sort_key: 1,
    SortBy: 'AlphabetDesc',
    sort_by_price: '',
  },
  {
    name: 'Harga Terendah',
    sort_key: 2,
    SortBy: '',
    sort_by_price: 1,
  },
  {
    name: 'Harga Tertinggi',
    sort_key: 3,
    SortBy: '',
    sort_by_price: -1,
  },
  {
    name: 'Promo',
    SortBy: 'Promo',
    sort_key: 4,
  },
]

const paymentMap = {
  1: {
    name: 'Credit Card',
    type: 'cc',
  },
  9: {
    name: 'Credit Card',
    type: 'cc',
  },
  3: {
    name: 'GoPay',
    type: 'qr',
  },
  8: {
    name: 'QRIS',
    type: 'qr',
  },
  10: {
    name: 'QRIS',
    type: 'qr',
  },
  11: {
    name: 'ShopeePay',
    type: 'qr',
  },
  4: {
    name: 'BCA Virtual Account',
    type: 'va',
  },
  5: {
    name: 'BNI Virtual Account',
    type: 'va',
  },
  6: {
    name: 'Permata Virtual Account',
    type: 'va',
  },
  7: {
    name: 'Mandiri Biller',
    type: 'biller',
  },
}

export default {
  url_instagram,
  url_twitter,
  url_facebook,
  url_youtube,
  genders,
  locationDelta,
  filterProductType,
  paymentMap,
}
