import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost } from '../../../utils/fetch'

export default {
  async getCart(token) {
    let responseJSON = await fetchGet(baseURL + '/cart/get', token)
    return responseJSON
  },

  async checkVoucher(promo_code, token) {
    let form = JSON.stringify({
      promo_code,
    })
    let responseJSON = await fetchPost(baseURL + '/voucher/check', form, token)
    return responseJSON
  },

  async createTransaction(data, token) {
    let form = JSON.stringify({
      ...data,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/create',
      form,
      token,
    )
    return responseJSON
  },

  async checkDelivery(CustomerAddressId, token) {
    let form = JSON.stringify({
      CustomerAddressId,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/checkDelivery',
      form,
      token,
    )
    return responseJSON
  },

  async getCourier(customer_address_id, lat, lng, store_id, token) {
    let form = JSON.stringify({
      customer_address_id,
      lat,
      lng,
      store_id,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/getcourier',
      form,
      token,
    )
    return responseJSON
  },

  async getTime(token) {
    let responseJSON = await fetchGet(
      baseURL + '/transaction/getdeliverytime',
      token,
    )
    return responseJSON
  },

  async checkPlasticUsage(cart_ids, store_id, token) {
    let form = JSON.stringify({ cart_ids, store_id })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/getplasticprediction',
      form,
      token,
    )
    return responseJSON
  },

  async getPaymentMethods(total_price, type, token) {
    let responseJSON = await fetchGet(
      baseURL +
        `/transaction/getAvailablePaymentMethod?total_price=${total_price}&type=${type}`,
      token,
    )
    return responseJSON
  },

  async getDonationInfo(token) {
    let responseJSON = await fetchGet(
      baseURL + '/transaction/getcurrentdonationinfo',
      token,
    )
    return responseJSON
  },

  async cod(transaction_id, cod_payment_amount, donation_fee, token) {
    let form = JSON.stringify({
      transaction_id,
      cod_payment_amount,
      donation_fee,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/createcod',
      form,
      token,
    )
    return responseJSON
  },

  async snapToken(TransactionId, digital_transaction_id, token) {
    let form = JSON.stringify({ TransactionId, digital_transaction_id })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/snaptoken',
      form,
      token,
    )
    return responseJSON
  },

  async getMyVoucher(voucher_scope = '', token) {
    let form = JSON.stringify({
      voucher_scope,
    })
    let responseJSON = await fetchPost(
      baseURL + `/voucher/myvoucher`,
      form,
      token,
    )
    return responseJSON
  },

  async searchVoucher(promo_code, token) {
    let form = JSON.stringify({
      promo_code,
    })
    let responseJSON = await fetchPost(baseURL + '/voucher/find', form, token)
    return responseJSON
  },

  async searchDigitalVoucher(promo_code, token) {
    let form = JSON.stringify({
      promo_code,
    })
    let responseJSON = await fetchPost(
      baseURL + '/voucher/digital/find',
      form,
      token,
    )
    return responseJSON
  },
}
