import is from 'is_js'
import moment from 'moment'
import forge from 'node-forge'
import master from './master'

moment.updateLocale('id', {
  months:
    'Januari_Februari_Maret_April_Mei_Juni_Juli_Agustus_September_Oktober_November_Desember'.split(
      '_',
    ),
  monthsShort: 'Jan_Feb_Mar_Apr_Mei_Jun_Jul_Agu_Sep_Okt_Nov_Des'.split('_'),
  monthsParseExact: true,
  weekdays: 'Minggu_Senin_Selasa_Rabu_Kamis_Jumat_Sabtu'.split('_'),
  weekdaysShort: 'Min_Sen_Sel_Rab_Kam_Jum_Sab'.split('_'),
  weekdaysMin: 'Mi_Se_Se_Ra_Ka_Ju_Sa'.split('_'),
  weekdaysParseExact: true,
})

export function deepCopy(arr) {
  return JSON.parse(JSON.stringify(arr))
}

export function formatMoney(value = 0) {
  return value
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function safariChecker(url) {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor)
  if (isSafari) return url + '#t=0.1'
  return url
}

export function numberOnly(value) {
  return value.toString().replace(/[^0-9]/g, '')
}

export function phoneNumberOnly(value) {
  value = value.toString().replace(/[^-\d]/g, '')
  if (value[0] == '0') value = ''
  return value
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function checkOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windowsphone'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'unknown'
}

export function goToRegister() {
  if (checkOS() == 'ios') {
    window.open(master?.url_appstore, '_blank')
    return false
  }

  window.open(master?.url_playstore, '_blank')
}

export function trimPhoneNumber(number) {
  if (number[0] == '0') {
    number = number.substring(1, number.length)
  }
  if (number[0] == '+' && number[1] == '6' && number[2] == '2') {
    number = number.substring(3, number.length)
  }
  return number
}

export function generatePhoneNumber(number) {
  if (number[0] == '0') {
    number = number.substring(1, number.length)
  }
  if (!number.includes('+62')) number = '+62' + number
  return number
}

export async function checkLocalData(key, hoursToUpdate = 24) {
  // local data consist of data & last_update
  let localData = localStorage.getItem(key)
  if (localData) {
    localData = await JSON.parse(localData)
  }

  return {
    ...localData,
    shouldUpdate:
      moment().diff(localData?.last_update, 'hours') > hoursToUpdate,
  }
}

export function strip(str) {
  if (str === null || str === '') return false
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '')
}

export function configureDeeplink(deeplink, history) {
  if (!deeplink) return false

  let splitted = deeplink.split(',')
  let path = splitted[0]
  splitted.shift()
  let params = '{}'
  if (splitted?.length > 0) {
    params = splitted.join(',')
    params = params
      ?.replaceAll("'", '"')
      ?.replaceAll('id', '"id"')
      ?.replaceAll('name', '"name"')
  }
  params = JSON.parse(params)

  switch (path) {
    case 'Homepage':
      history.push('/')
      break
    case 'TransactionDetails':
      history.push('/account/transactions/details/' + params?.id)
      break
    case 'DigitalTransactionDetails':
      history.push('/account/digital/transactions/details/' + params?.id)
      break
    case 'ProductDetails':
      history.push('/product/details/' + params?.id || '')
      break
    case 'ProductSearch':
      history.push('/product/search?sc=' + params?.id || '')
      break
    case 'ProductSubcategory':
      history.push('/product/search?c=' + params?.id || '')
      break
    default:
      break
  }
}

export function calculateDiscount(price = 0, type = '', value = 0) {
  price = parseInt(price || 0)
  value = parseInt(value || 0)

  let discount = 0
  let discount_percent = 0
  if (type == 'Persentase') {
    discount = (price * value) / 100
    discount_percent = value
    price -= discount
  } else if (type == 'Langsung') {
    price -= value
    discount = value
    discount_percent = (value / price) * 100
  }

  if (!price) price = 0
  if (!discount) discount = 0
  if (!discount_percent) discount_percent = 0
  discount_percent = Math.round(discount_percent)

  return { price, discount, discount_percent }
}

export function compare(name, type, a, b) {
  let tempA = is.string(a[name]) ? a[name].toUpperCase() : a[name]
  let tempB = is.string(b[name]) ? b[name].toUpperCase() : b[name]

  let comparison = 0
  if (tempA > tempB) {
    comparison = type == 'asc' ? 1 : -1
  } else if (tempA < tempB) {
    comparison = type == 'asc' ? -1 : 1
  }
  return comparison
}

export function hash_sha256(data) {
  let sha = forge.md.sha256.create()
  sha.update(data)
  return sha.digest().toHex()
}

export function removeQueryParams(url, paramKey) {
  let splitted = url.split('?')
  splitted = splitted[1]
  let params = splitted.split('&')
  params = params.filter((g) => !g.includes(paramKey))
  return params.join('&')
}

export const clipboard = (content = '') => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(content)
    return
  }
  fallbackCopyTextToClipboard(content)
}

//https://stackoverflow.com/a/30810322
const fallbackCopyTextToClipboard = (text) => {
  let textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
