import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import { Image, Relative, Text } from '../../../assets/components/components'
import images from '../../../assets/images'
import { colors } from '../../../assets/styles/styles'
import Button from '../../../components/Button/Button'
import InputPassword from '../../../components/Input/InputPassword'
import api from './api'

const inputs = {
  password: '',
  confirmpassword: '',
}

const errs = {
  password: '',
  confirmpassword: '',
}

const ResetPassword = ({ location }) => {
  const [forms, setForms] = useState({ ...inputs })
  const [errors, setErrors] = useState({ ...errs })
  const [fetching, setFetching] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const logout = useSelector((state) => state.logout)

  const onChange = (e) => {
    const value =
      e.target.type == 'checkbox' ? e.target.checked : e.target.value
    if (errors[e.target.name]) setErrors({ ...errors, [e.target.name]: '' })
    setForms({ ...forms, [e.target.name]: value })
  }

  const validate = (form) => {
    let count = 0
    let errors = { ...errs }
    const { password, confirmpassword } = form

    if (!password) {
      errors.password = 'Kata sandi harus diisi'
      count++
    }
    if (password.length < 6) {
      errors.password = 'Kata sandi minimal 6 karakter'
      count++
    }
    if (!confirmpassword) {
      errors.confirmpassword = 'Konfirmasi kata sandi harus diisi'
      count++
    }
    if (password !== confirmpassword) {
      errors.confirmpassword =
        'Konfirmasi kata sandi harus sama dengan kata sandi'
      count++
    }
    setErrors(errors)
    return count
  }

  const [state, submit] = useAsyncFn(
    async (phone_number, customer_id, otp, forms) => {
      if (validate(forms) > 0) return false
      dispatch({ type: 'loading' })
      let res = await api.resetPassword(
        phone_number,
        otp,
        forms.password,
        forms.confirmpassword,
        customer_id,
      )

      dispatch({ type: 'unloading' })
      if (!res.success) {
        toast.error(res.message)
        return false
      }

      toast.success(res.message)
      history.push('/login')
    },
    [],
  )

  return (
    <section className="register">
      <Container>
        <Row>
          <Col xs="12" sm="3"></Col>
          <Col xs="12" sm="6">
            <Relative
              padding="25px"
              border={`1px solid ${colors.border}`}
              borderRadius="5px"
            >
              <Image
                src={images.icons.passwordred}
                width="70px"
                margin="0 auto 15px"
                display="block"
              />
              <Text textAlign="center" margin="0 0 30px">
                Verifikasi berhasil. Silakan membuat kata sandi baru.
              </Text>
              <InputPassword
                id="password"
                name="password"
                label="Kata Sandi"
                value={forms.password}
                error={errors.password}
                onChange={onChange}
              />
              <InputPassword
                id="confirmpassword"
                name="confirmpassword"
                label="Konfirmasi Kata Sandi"
                value={forms.confirmpassword}
                error={errors.confirmpassword}
                onChange={onChange}
              />

              <Button
                color="primary"
                block
                onClick={() => {
                  submit(
                    location?.state?.phone_number,
                    location?.state?.customer_id,
                    location?.state?.otp,
                    forms,
                  )
                }}
              >
                Lanjutkan
              </Button>
            </Relative>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ResetPassword
