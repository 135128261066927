import IMask from 'imask'
import React, { useState } from 'react'
import { Card, Text } from '../../../../../assets/components/components'
import SelectLooks from '../../../../../components/Button/SelectLooks'
import Input from '../../../../../components/Input/Input'
import ModalPaymentMethod from './ModalPaymentMethod'

const PaymentMethod = ({
  forms,
  onChange,
  calculations,
  useCOD = true,
  data,
  refetch,
  type,
}) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Card padding="20px">
        <SelectLooks
          label="Pembayaran"
          value={forms?.payment?.name}
          placeholder="Pilih Metode Pembayaran"
          onClick={() => setModal(true)}
        />
      </Card>

      {forms?.payment?.payment_method_id == 1 ||
      forms?.payment?.payment_method_id == 9 ? (
        <Card padding="20px">
          <Text fontWeight="bold" fontSize="16px" margin="0 0 25px">
            Card Detail
          </Text>

          <Input
            inputmode="numeric"
            id="cc_number"
            name="cc_number"
            label="Card Number"
            value={forms.cc_number}
            valueType="number"
            onChange={(e) => {
              let masked = IMask.createMask({
                mask: '0000-0000-0000-0000',
              })
              let value = masked.resolve(e.target.value)
              onChange({ name: 'cc_number', value })
            }}
            onBlur={() => {
              if (forms?.cc_number?.length > 6) refetch && refetch(data, true)
            }}
          />

          <Input
            inputmode="numeric"
            id="cc_period"
            name="cc_period"
            label="Expiry Period (MM/YY)"
            value={forms.cc_period}
            onChange={(e) => {
              let masked = IMask.createMask({
                mask: '00/00',
              })
              let value = masked.resolve(e.target.value)
              onChange({ name: 'cc_period', value })
            }}
          />

          <Input
            id="cc_cvv"
            name="cc_cvv"
            label="CVV"
            value={forms.cc_cvv}
            maxLength={3}
            valueType="number"
            onChange={(e) =>
              onChange({ name: 'cc_cvv', value: e.target.value })
            }
            type="password"
            wrapperStyle={{ marginBottom: 0 }}
            inputmode="numeric"
          />
        </Card>
      ) : null}

      {modal && (
        <ModalPaymentMethod
          open={modal}
          close={() => setModal(false)}
          forms={forms}
          onChange={onChange}
          calculations={calculations}
          useCOD={useCOD}
          type={type}
        />
      )}
    </>
  )
}

export default PaymentMethod
