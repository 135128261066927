const timeouttime = 60000
export const errorTimeoutMessage =
  'Koneksi terputus. Mohon periksa jaringan internet Anda'
export const errorServerMessage = 'Gagal memuat. Mohon coba kembali'
export const errorForbiddenMessage =
  'Sesi kamu telah habis. Silahkan login kembali'

export async function generateQueryParams(
  obj,
  alias = {},
  blacklist = { total_data: true, total_page: true, current_page: true },
) {
  let query = ''
  Object.keys(obj).map((g, i) => {
    if (!blacklist[g]) {
      if (i > 0) query += '&'
      query += `${alias[g] || g}=${obj[g]}`
    }
  })
  return query
}

export function timeout(ms, promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error('timeout'))
    }, ms)
    promise.then(resolve, reject)
  })
}

export async function fetchGet(url, token, withToken = true) {
  let responseJSON
  let headers = {
    Accept: 'application/json',
  }

  if (withToken) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  if (process.env.NODE_ENV != 'production') {
    console.log(url)
    console.log(token)
  }

  await timeout(
    timeouttime,
    fetch(url, {
      headers,
    }),
  )
    .then(async function (response) {
      if (response.status === 500) {
        let res = await response.json()
        console.log(res)

        responseJSON = {
          success: false,
          message: errorServerMessage,
        }
      } else if (response.status === 401 || response.status === 404) {
        responseJSON = {
          success: false,
          message: errorForbiddenMessage,
          forbidden: true,
        }
      } else {
        responseJSON = await response.json()
        if (!responseJSON.status) {
          responseJSON = {
            data: responseJSON,
            status: 'success',
          }
        }
        responseJSON = {
          ...responseJSON,
          success:
            responseJSON.status === 'success' || responseJSON.status === 'OK',
          data: responseJSON.data || responseJSON.Data,
          message: responseJSON.message || responseJSON.Message,
        }
        if (responseJSON.message == 'Unauthenticated')
          responseJSON.forbidden = true
        if (process.env.NODE_ENV != 'production') console.log(responseJSON)
      }
    })
    .catch(function (error) {
      responseJSON = {
        success: false,
        message: errorTimeoutMessage,
      }
    })
  return responseJSON
}

export async function fetchPost(
  url,
  data,
  token,
  useJSON = true,
  withToken = true,
) {
  let responseJSON
  let headers = {
    Accept: 'application/json',
  }

  if (useJSON) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    }
  }

  if (withToken) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  if (process.env.NODE_ENV != 'production') {
    console.log(url)
    console.log(data)
  }

  await timeout(
    timeouttime,
    fetch(url, {
      method: 'POST',
      headers,
      body: data,
    }),
  )
    .then(async function (response) {
      // if (url.includes('/doLogin')) {
      //   let res = await response.json()
      //   console.warn(res)
      // }

      if (response.status === 500) {
        let res = await response.json()
        console.log(res)

        responseJSON = {
          success: false,
          message: errorServerMessage,
        }
      } else if (response.status === 401 || response.status === 404) {
        responseJSON = {
          success: false,
          message: errorForbiddenMessage,
          forbidden: true,
        }
      } else {
        responseJSON = await response.json()
        if (!responseJSON?.status) {
          responseJSON = {
            data: responseJSON,
            status: 'success',
          }
        }
        responseJSON = {
          ...responseJSON,
          success:
            responseJSON?.status === 'success' || responseJSON?.status === 'OK',
          data: responseJSON?.data || responseJSON?.Data,
          message: responseJSON?.message || responseJSON?.Message,
        }
        if (responseJSON?.message == 'Unauthenticated')
          responseJSON.forbidden = true
        if (process.env.NODE_ENV != 'production') console.log(responseJSON)
      }
    })
    .catch(async function (error) {
      responseJSON = {
        success: false,
        message: errorTimeoutMessage,
      }
    })
  return responseJSON
}
