import moment from 'moment'
import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'
import InfoList from '../../../../components/View/InfoList'

const Garuda = ({ data }) => {
  return (
    <>
      <Flex>
        <FlexCell>
          <Image
            source={data?.type?.picture}
            width={30}
            height={30}
            objectFit="contain"
          />
        </FlexCell>
        <FlexCell padding="0 0 0 15px">
          <Text fontWeight="bold">
            {data?.details?.customerData?.passanger_name}
          </Text>
          <Text>{data?.number}</Text>
          <Text>{data?.details?.customerData?.nomor_pembayaran}</Text>
        </FlexCell>
      </Flex>

      <CardInfo
        title={'Info Penerbangan'}
        content={
          <>
            <InfoList
              title="Kelas"
              content={`${data?.details?.customerData?.carrier} (
                  ${data?.details?.customerData?.kelas})`}
            />

            <InfoList
              title="Tujuan"
              content={`${data?.details?.customerData?.From} -{' '}
							${data?.details?.customerData?.to}`}
            />

            <InfoList
              title="Waktu"
              content={`
							${moment(data?.details?.customerData?.hari_bulan_keberangkatan, 'DDMM').format(
                'DD MMMM',
              )}
							,
							${moment(data?.details?.customerData?.jam_keberangkatan, 'HHmm').format(
                'HH:mm',
              )}`}
            />
          </>
        }
      />
    </>
  )
}

export default Garuda
