import React from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Relative, Text } from '../../../../assets/components/components'
import { colors } from '../../../../assets/styles/styles'
import Button from '../../../../components/Button/Button'
import Input from '../../../../components/Input/Input'
import { generatePhoneNumber } from '../../../../utils/functions'
import api from '../api'
import AdditionalField from './AdditionalField'
import RechargeBox from './Recharge/RechargeBox'

const Right = ({ forms, setForms, errors, onChange }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const activeAddress = useSelector((state) => state.activeAddress)
  const activeStore = useSelector((state) => state.activeStore)
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const validate = (forms) => {
    const { type, amount, month } = forms
    if (type?.product_code == '070002' && !amount?.value) {
      toast.error('Mohon pilih nominal token')
      return false
    }
    if (type?.product_code == '080003' && !month?.value) {
      toast.error('Mohon pilih bulan pembayaran')
      return false
    }
    return true
  }

  const [state, submit] = useAsyncFn(async (forms, token) => {
    if (!token) history.push('/login')
    if (!validate(forms)) return false

    dispatch({ type: 'loading' })
    let number = forms?.number
    if (forms?.type?.product_code == '080003')
      number += forms?.month?.value || ''
    let res = await api.checkBiller(
      forms?.type?.product_code || '',
      number,
      forms?.amount?.value || '',
      token,
    )
    dispatch({ type: 'unloading' })
    if (!res.success) {
      if (res.forbidden) logout()
      toast.error(res.message)
      return false
    }
    let response = await JSON.parse(res.data?.response)
    let data = {
      ...res.data,
      billing_id: res.data?.billing_log_id,
      amount: parseInt(response?.amount || 0),
      fee: parseInt(response?.feeAmount || 0),
      customer_name: response?.customerData?.customerName || '',
      number,
      type: forms?.type,
      details: response,
    }
    history.push('/digital/checkout', { data })
  }, [])

  const submitRecharge = () => {
    let data = {
      number: generatePhoneNumber(forms?.number),
      numberData: forms?.numberData,
      type: forms?.type,
      details: forms?.recharge_or_package_details,
    }
    history.push('/digital/checkout', { data })
  }

  return (
    <>
      {forms?.type?.id == -1 ? (
        <RechargeBox
          forms={forms}
          setForms={setForms}
          errors={errors}
          onChange={onChange}
          submit={submitRecharge}
        />
      ) : (
        <Relative
          borderRadius="5px"
          padding="20px"
          border={`1px solid ${colors.border}`}
        >
          <Text fontWeight="bold" fontSize="20px" margin="0 0 30px">
            Masukkan nomor pelanggan
          </Text>

          <Input
            id="number"
            name="number"
            label="Nomor Pelanggan"
            value={forms.number}
            error={errors.number}
            onChange={onChange}
          />

          <AdditionalField
            type={forms.type?.product_code}
            forms={forms}
            setForms={setForms}
            errors={errors}
            onChange={onChange}
          />
          <Button color="primary" block onClick={() => submit(forms, token)}>
            Bayar Sekarang
          </Button>
        </Relative>
      )}
    </>
  )
}

export default Right
