import moment from 'moment'
import React from 'react'
import CardInfo from '../../../../components/View/CardInfo'
import { formatMoney } from '../../../../utils/functions'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const MNCVision = ({ data, log }) => {
  let period = log?.customerData?.servicePeriod

  // if(period?.length == 2) {

  // }
  return (
    <>
      <ProductDetails
        name={data?.product_name}
        desc={`${log?.customerData?.customerName} (
					${log?.customerData?.customerId})`}
      />

      <CardInfo
        title="Detail Tagihan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(
                log?.timeStampServer,
                'DD-MM-YYYY HH:mm:ss',
              ).format('DD MMM YYYY - HH:mm')}
            />
            <InfoList title="Nama" content={log?.customerData?.customerName} />
            <InfoList
              title="ID Pelanggan"
              content={log?.customerData?.customerId}
            />

            <InfoList title="Periode Bayar" content={period} />

            <InfoList
              title="Jumlah Tagihan"
              content={'Rp ' + formatMoney(parseInt(log?.amount || 0))}
            />

            {log?.traxId ? (
              <InfoList
                title="Nomor Referensi"
                content={log?.traxId}
                withCopy
              />
            ) : null}
          </>
        }
      />
    </>
  )
}

export default MNCVision
