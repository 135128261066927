import React from 'react'
import Spinners from './index'
import classNames from 'classnames'
import '../../assets/css/spinkit.min.css'

const Spinner = ({
  name,
  size = 30,
  color,
  center = true,
  margin,
  white,
  ...props
}) => {
  let Spin = Spinners[name]
  return (
    <Spin
      {...props}
      className={classNames('spinner', { white })}
      size={size}
      style={{
        width: size,
        height: size,
        margin: margin ? margin : center ? '0 auto' : '0',
      }}
    />
  )
}

export default Spinner
