import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'

const AppComponent = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<AppComponent />, rootElement)
} else {
  render(<AppComponent />, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
