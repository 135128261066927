import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { FiCopy } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import {
  Card,
  Flex,
  Image,
  Relative,
  Text,
} from '../../../../assets/components/components'
import images from '../../../../assets/images'
import { colors } from '../../../../assets/styles/styles'
import Button from '../../../../components/Button/Button'
import { clipboard } from '../../../../utils/functions'
import master from '../../../../utils/master'

const PaymentDetails = ({ data, refetch }) => {
  const history = useHistory()

  const finishIframe = (message) => {
    const target = document.getElementById('cc-iframe').contentWindow
    if (message.source == target && message.data?.status_code == '200')
      refetch()
    // history.goBack()
  }

  useEffect(() => {
    if (master.paymentMap[data?.payment_method_id]?.type != 'cc') return
    window.addEventListener('message', finishIframe)
    return () => window.removeEventListener('message', finishIframe)
  }, [])

  const renderCopy = (message) => (
    <strong
      onClick={() => {
        clipboard(message)
        toast.success('Berhasil disalin')
      }}
      style={{ cursor: 'pointer' }}
    >
      {message}{' '}
      <FiCopy
        color={colors?.primary}
        size={18}
        cursor="pointer"
        style={{ marginLeft: 7 }}
      />
    </strong>
  )

  const renderMethod = () => {
    switch (master.paymentMap[data?.payment_method_id]?.type) {
      case 'cc':
        return (
          <Flex justifyContent="center">
            <iframe
              id="cc-iframe"
              className="cc-iframe"
              src={data?.payment?.redirect_url || ''}
            />
          </Flex>
        )

      case 'qr':
        return (
          <Flex column justifyContent="center">
            <Image
              src={data?.payment?.qr_link}
              width="300px"
              height="300px"
              objectFit="contain"
            />
            <Text margin="15px 0 0">
              Scan QRIS di atas untuk membayar tagihan
            </Text>
          </Flex>
        )

      case 'va':
        return (
          <Flex column justifyContent="center">
            <Text margin="0 0 15px">
              Lakukan pembayaran ke nomor{' '}
              {master.paymentMap[data?.payment_method_id]?.name} berikut
            </Text>
            {renderCopy(data?.payment?.va_number || '')}
          </Flex>
        )

      case 'biller':
        return (
          <Flex column justifyContent="center" padding="15px 0">
            <Text margin="0 0 20px">
              Lakukan pembayaran ke Mandiri Biller dengan kode berikut
            </Text>
            <table>
              <tr>
                <td>Bill Key</td>
                <td style={{ padding: '0 7px' }}>:</td>
                <td>{renderCopy(data?.payment?.bill_key || '')}</td>
              </tr>
              <tr>
                <td>Biller Code</td>
                <td style={{ padding: '0 7px' }}>:</td>
                <td>{renderCopy(data?.payment?.biller_code || '')}</td>
              </tr>
            </table>
          </Flex>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Card>
        <Image src={images.figure.paymentart} width="100%" height="auto" />
        <Relative padding="30px">{renderMethod()}</Relative>
      </Card>
      <Row>
        <Col xs="12" sm="9" />
        <Col xs="12" sm="3">
          <Button color="primary" block onClick={refetch}>
            Cek Status Pembayaran
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default PaymentDetails
