import React, { useState } from 'react'
import FetchHandler from '../../../../components/State/FetchHandler'
import List from './List'
import ModalDetails from './ModalDetails'

const Data = ({ data, loading, error, refetch, online }) => {
  const [modalDetails, setModalDetails] = useState(false)

  return (
    <>
      <FetchHandler
        loading={loading}
        error={error}
        refetch={refetch}
        showEmpty={data?.length == 0}
        content={
          <>
            {data?.map((g, i) => {
              return (
                <List
                  key={i}
                  data={g}
                  onClick={() => setModalDetails(g)}
                  online={online}
                />
              )
            })}
          </>
        }
      />

      {modalDetails != '' && (
        <ModalDetails
          open={modalDetails != ''}
          close={() => setModalDetails('')}
          data={modalDetails}
          usable={false}
        />
      )}
    </>
  )
}

export default Data
