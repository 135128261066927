import React from 'react'
import { Flex, FlexCell, Text } from '../../assets/components/components'

const InfoList = ({
  title,
  content,
  titleProps,
  contentProps,
  titleFlex,
  contentFlex,
  margin = '0 0 15px',
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
}) => {
  return (
    <>
      <Flex
        margin={margin}
        alignItems={alignItems}
        justifyContent={justifyContent}
      >
        <FlexCell flex={titleFlex}>
          <Text {...titleProps}>{title}</Text>
        </FlexCell>
        <FlexCell flex={contentFlex}>
          <Text {...contentProps}>{content || '-'}</Text>
        </FlexCell>
      </Flex>
    </>
  )
}

export default InfoList
