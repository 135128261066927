import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async createPayment(TransactionId, digital_transaction_id, token_id, token) {
    let form = JSON.stringify({
      TransactionId,
      digital_transaction_id,
      token_id,
    })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/createCorePayment',
      form,
      token,
    )
    return responseJSON
  },
}
