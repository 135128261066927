import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  Separator,
  Text,
} from '../../../../../assets/components/components'
import { colors } from '../../../../../assets/styles/styles'
import Button from '../../../../../components/Button/Button'
import InfoList from '../../../../../components/View/InfoList'
import { formatMoney } from '../../../../../utils/functions'

const Total = ({ data, forms, calculations, plasticDetails, presubmit }) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  return (
    <Card padding="20px">
      <Text fontWeight="bold" fontSize="17px" margin="0 0 15px">
        Total Biaya
      </Text>

      <InfoList
        justifyContent="space-between"
        title="Sub Total"
        content={`Rp${formatMoney(calculations?.subtotal || '0')}`}
      />
      <InfoList
        justifyContent="space-between"
        title="Biaya Admin"
        content={`Rp${formatMoney(calculations?.servicefee || '0')}`}
      />
      <InfoList
        justifyContent="space-between"
        title="Biaya Admin Pembayaran"
        content={`Rp${formatMoney(plasticDetails?.payment_fee || '0')}`}
      />
      <InfoList
        justifyContent="space-between"
        title="Potongan"
        content={`-Rp${formatMoney(calculations?.discount || '0')}`}
        contentProps={{ color: colors.red }}
      />
      {calculations?.bundle_discount > 0 ? (
        <InfoList
          justifyContent="space-between"
          title="Potongan Bundle"
          content={`-Rp${formatMoney(calculations?.bundle_discount || '0')}`}
          contentProps={{ color: colors.red }}
        />
      ) : null}

      <Separator margin="0 0 15px" />

      <InfoList
        justifyContent="space-between"
        title="Total"
        content={`Rp${formatMoney(calculations?.total || '0')}`}
        contentProps={{ fontWeight: 'bold', fontSize: '17px' }}
      />

      <Button color="primary" block onClick={presubmit}>
        Bayar Sekarang
      </Button>
    </Card>
  )
}

export default Total
