import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import {
  Flex,
  FlexCell,
  Image,
  Relative,
  Text,
} from '../../../../assets/components/components'
import images from '../../../../assets/images'
import { colors } from '../../../../assets/styles/styles'
import FetchHandler from '../../../../components/State/FetchHandler'
import { formatMoney } from '../../../../utils/functions'
import Statuses from './Statuses'

const Data = ({ data, loading, error, refetch }) => {
  const history = useHistory()

  return (
    <>
      <FetchHandler
        loading={loading}
        error={error}
        refetch={refetch}
        showEmpty={data?.length == 0}
        content={
          <>
            {data?.map((g, i) => {
              return (
                <Relative
                  key={i}
                  padding="15px"
                  margin="20px 0 0"
                  border={`1px solid ${colors.border}`}
                  borderRadius="5px"
                >
                  <Row>
                    <Col xs="6" sm="6">
                      <Text fontSize="14px">No Pesanan</Text>
                      <Text fontSize="15px">{g?.transaction_number}</Text>
                    </Col>
                    <Col xs="6" sm="6">
                      <Statuses status={g.status} />
                      <Text
                        color={colors.secondary}
                        fontWeight="bold"
                        textAlign="right"
                        fontSize="14px"
                        cursor="pointer"
                        onClick={() =>
                          history.push(
                            '/account/digital/transactions/details/' +
                              g.digital_transaction_id,
                          )
                        }
                      >
                        Lihat Rincian
                      </Text>
                    </Col>
                  </Row>

                  <Flex>
                    <FlexCell>
                      <Image
                        src={
                          g.type == 'Biller'
                            ? images.icons.billing
                            : images.icons.rechargeblue
                        }
                        width="22px"
                        height="auto"
                      />
                    </FlexCell>
                    <FlexCell padding="0 0 0 15px">
                      <Text fontWeight="bold" margin="15px 0 0">
                        {g.fullname}
                      </Text>
                      <Text>
                        {moment(g.created_at).format('DD MMM YYYY - HH:mm')}
                      </Text>
                    </FlexCell>
                  </Flex>

                  <Relative margin="30px 0 0">
                    <Row>
                      <Col xs="6" sm="6">
                        <Text fontSize="14px">Total Tagihan</Text>
                      </Col>
                      <Col xs="6" sm="6">
                        <Text fontWeight="bold" textAlign="right">
                          Rp{' '}
                          {formatMoney(
                            g?.total_price - (g?.delivery_discount || 0),
                          )}
                        </Text>
                      </Col>
                    </Row>
                  </Relative>
                </Relative>
              )
            })}
          </>
        }
      />
    </>
  )
}

export default Data
