import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

const Tabs = ({ list = [], activeIndex = 0 }) => {
  const [active, setActive] = useState(0)

  let content = list[active]?.content

  useEffect(() => {
    setActive(activeIndex || 0)
  }, [activeIndex])

  return (
    <>
      <div className="tabs">
        {list.map((g, i) => {
          let act = i == active
          return (
            <div
              className={classNames('tab-list', { active: act })}
              onClick={() => {
                setActive(i)
                g.onClick && g.onClick()
              }}
            >
              {g?.name}
            </div>
          )
        })}
      </div>

      {content || null}
    </>
  )
}

export default Tabs
