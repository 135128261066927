import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Select from '../../../../components/Input/Select'

const amountlist = [
  {
    name: 'Rp 50.000',
    value: '50000',
  },
  {
    name: 'Rp 100.000',
    value: '100000',
  },
  {
    name: 'Rp 250.000',
    value: '250000',
  },
]

const AdditionalField = ({ type, forms, setForms, errors, onChange }) => {
  const [months, setMonths] = useState([])

  useEffect(() => {
    if (type == '080003') {
      let mon = []
      let max = 12
      for (let i = 0; i < max; i++) {
        let name = moment().add(i, 'month').format('MMMM YYYY')
        mon.push({
          name,
          value: `${i < 10 ? '0' : ''}${i}`,
        })
      }
      setMonths(mon)
    }
  }, [type])

  return (
    <>
      {type == '070002' ? (
        <Select
          id="amount"
          name="amount"
          label="Nominal"
          value={forms.amount}
          error={errors.amount}
          onChange={onChange}
          options={amountlist}
        />
      ) : type == '080003' ? (
        <Select
          id="month"
          name="month"
          label="Bulan Bayar"
          placeholder="Pilih Bulan Bayar"
          value={forms.month}
          error={errors.month}
          onChange={onChange}
          options={months}
        />
      ) : null}
    </>
  )
}

export default AdditionalField
