import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async register(nama, email, password, phone_number, gender, ponta_number) {
    let form = JSON.stringify({
      nama,
      email,
      password,
      phone_number,
      gender,
      ponta_number,
    })
    let responseJSON = await fetchPost(
      baseURL + '/register',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resendOTP(id) {
    let form = JSON.stringify({})
    let responseJSON = await fetchPost(
      baseURL + '/resendotp/' + id,
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async validateOTP(otp_code, customer_id) {
    let form = JSON.stringify({ otp_code, customer_id })
    let responseJSON = await fetchPost(
      baseURL + '/validateotp',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },
}
