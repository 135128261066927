import React from 'react'
import { useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Col, Row } from 'reactstrap'
import { Flex, Image, Text } from '../../../../assets/components/components'
import images from '../../../../assets/images'
import { colors } from '../../../../assets/styles/styles'
import IconWrapper from '../../../../components/View/IconWrapper'

const Left = ({ forms, setForms }) => {
  const billers = useSelector((state) => state.billers)

  const list = [
    {
      id: -1,
      name: 'Pulsa & Paket Data',
      picture: images.icons.rechargeblue,
      type: 'Recharge',
    },
    ...billers,
  ]

  return (
    <>
      <Flex margin="0 0 30px">
        <IconWrapper icon={images.icons.billing} />
        <Text fontWeight="bold" fontSize="20px" margin="0 0 0 15px">
          Pilih Pembayaran Digital
        </Text>
      </Flex>
      <Row>
        {list?.map((g, i) => {
          let active = forms?.type?.id == g?.id
          return (
            <Col key={i} xs="6" sm="4" style={{ marginBottom: 20 }}>
              <Flex
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                width="100%"
                height="130px"
                padding="15px"
                cursor="pointer"
                border={`1px solid ${active ? colors.primary : colors.border}`}
                // backgroundColor={active ? colors.primary10 : colors.white}
                onClick={() => setForms({ ...forms, type: g })}
              >
                <Image src={g.picture} width={g.id == -1 ? '40%' : '65%'} />
              </Flex>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default Left
