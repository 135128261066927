import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import { Image, Relative, Text } from '../../../assets/components/components'
import images from '../../../assets/images'
import { colors } from '../../../assets/styles/styles'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import { generatePhoneNumber } from '../../../utils/functions'
import api from './api'

const inputs = {
  phone: '',
}

const errs = {
  phone: '',
}

const ForgotPassword = ({ location }) => {
  const [forms, setForms] = useState({ ...inputs })
  const [errors, setErrors] = useState({ ...errs })
  const [fetching, setFetching] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const logout = useSelector((state) => state.logout)

  const onChange = (e) => {
    const value =
      e.target.type == 'checkbox' ? e.target.checked : e.target.value
    if (errors[e.target.name]) setErrors({ ...errors, [e.target.name]: '' })
    setForms({ ...forms, [e.target.name]: value })
  }

  const validate = (form) => {
    let count = 0
    let errors = { ...errs }
    const { phone } = form

    if (!phone) {
      errors.phone = 'Nomor ponsel harus diisi'
      count++
    }
    if (phone?.length < 10) {
      errors.phone = 'Nomor ponsel minimal 10 karakter'
      count++
    }
    setErrors(errors)
    return count
  }

  const [state, submit] = useAsyncFn(async (form) => {
    if (validate(form) > 0) return false
    dispatch({ type: 'loading' })
    let phone_number = generatePhoneNumber(form?.phone)
    let res = await api.forgotPassword(phone_number)

    dispatch({ type: 'unloading' })
    if (!res.success) {
      if (res.forbidden) logout()
      toast.error(res.message)
      return false
    }

    toast.success(res.message)
    history.push('/forgot/otp', {
      phone_number,
      customer_id: res?.data?.customer_id,
    })
  }, [])

  return (
    <section className="register">
      <Container>
        <Row>
          <Col xs="12" sm="3"></Col>
          <Col xs="12" sm="6">
            <Relative
              padding="25px"
              border={`1px solid ${colors.border}`}
              borderRadius="5px"
            >
              <Image
                src={images.icons.passwordred}
                width="70px"
                margin="0 auto 15px"
                display="block"
              />
              <Text textAlign="center" margin="0 0 30px">
                Silakan masukkan nomor telepon yang telah Anda daftarkan di
                MidiKring.
              </Text>
              <Input
                id="phone"
                name="phone"
                label="Nomor Ponsel"
                value={forms.phone}
                error={errors.phone}
                onChange={onChange}
                valueType="phone"
                placeholder="8123xxxxx"
              />

              <Button
                color="primary"
                block
                onClick={() => {
                  submit(forms)
                }}
              >
                Lanjutkan
              </Button>
            </Relative>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ForgotPassword
