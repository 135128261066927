import React from 'react'
import toast from 'react-hot-toast'
import { Text } from '../../assets/components/components'
import { colors } from '../../assets/styles/styles'
import { clipboard } from '../../utils/functions'

const Copy = ({ message }) => {
  const copy = () => {
    clipboard(message)
    toast.success('Berhasil disalin')
  }
  return (
    <>
      <Text
        fontWeight="bold"
        fontSize="14px"
        cursor="pointer"
        color={colors.secondary}
        onClick={copy}
      >
        Salin
      </Text>
    </>
  )
}

export default Copy
