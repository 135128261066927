import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { breakpoints, colors, fonts } from '../styles/styles'
const {
  black,
  primary,
  primary20,
  primary80,
  border,
  secondary,
  redsoft,
  white,
  blackpure,
  grey,
} = colors
const { title, content, roboto } = fonts

const btn = css`
  display: inline-block;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 15px;
  transition: 0.1s all;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
    box-shadow: none;
  }
`

const blackghost = '#777'

const btncolor = {
  primary: css`
    background-color: ${primary};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'primary-link': css`
    background-color: transparent;
    color: ${primary};
    &:hover {
      opacity: 0.9;
    }
  `,
  'primary-ghost': css`
    background-color: transparent;
    color: ${primary};
    border: 1px solid ${primary};
    &:hover {
      background-color: ${primary};
      color: ${white};
    }
  `,
  secondary: css`
    background-color: ${secondary};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'secondary-ghost': css`
    background-color: transparent;
    color: ${secondary};
    border: 1px solid ${secondary};
    &:hover {
      background-color: ${secondary};
      color: ${white};
    }
  `,
  'secondary-link': css`
    background-color: transparent;
    color: ${secondary};
    &:hover {
      opacity: 0.9;
    }
  `,
  'red-ghost': css`
    background-color: transparent;
    color: ${redsoft};
    border: 1px solid ${redsoft};
    &:hover {
      background-color: ${redsoft};
      color: ${white};
    }
  `,
  'black-ghost': css`
    background-color: transparent;
    color: ${blackpure};
    border: 1px solid ${blackpure};
    &:hover {
      background-color: ${blackpure};
      color: ${white};
    }
  `,
  white: css`
    background-color: ${white};
    color: ${primary};
    &:hover {
      opacity: 0.9;
    }
  `,
  black: css`
    background-color: ${blackpure};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'grey-ghost': css`
    background-color: transparent;
    border: 1px solid ${grey};
    color: ${black};
    font-weight: 500;
    &:hover {
      opacity: 0.9;
    }
  `,
}

const Relative = styled.div`
  position: relative;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Span = styled.span`
  ${(props) => props};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Fixed = styled.div`
  position: fixed;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`

const Absolute = styled.div`
  position: absolute;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  cursor: ${(props) => props.cursor};
  transform: ${(props) => props.transform};
  z-index: ${(props) => props.zIndex};
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const FormGroup = styled.div`
  position: relative;
  margin: ${(props) => props.margin || '0 0 30px'};
  width: 100%;
`

const Flex = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'center')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  border: ${(props) => props.border};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.borderRadius};
  overflow: ${(props) => props.overflow};
  white-space: ${(props) => props.whiteSpace};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const FlexCell = styled.div`
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const FlexCellShadow = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
  -webkit-box-shadow: 0 4px 6px -6px black;
  -moz-box-shadow: 0 4px 6px -6px black;
  box-shadow: 0 4px 6px -6px black;
`

const InlineBlock = styled.div`
  display: inline-block;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  cursor: ${(props) => props.cursor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const WhiteSpace = styled.div`
  white-space: ${(props) => props.type};
  padding: ${(props) => props.padding};
`

const Text = styled.div`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || black};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  letter-spacing: ${(props) => props.letterSpacing};
  line-height: ${(props) => props.lineHeight};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height || 'auto'};
  margin: ${(props) => props.margin};
  object-fit: ${(props) => props.objectFit};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.pointer};
  display: ${(props) => props.display};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Separator = styled.div`
  border-bottom: ${(props) => props.borderWidth || '1px'}
    ${(props) => props.borderStyle || 'solid'}
    ${(props) => props.borderColor || border};
  margin: ${(props) => props.margin};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Divider = styled.div`
  display: inline-block;
  width: 100%;
  background-color: #eeeeee;
  margin: 0;
  height: 1px;
`

const Icon = styled.i`
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
`

const BoxShadow = styled.div`
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  padding: ${(props) => props.padding || '20px 25px'};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
`

const Card = styled.div`
  position: relative;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  padding: ${(props) => props.padding || '0'};
  border-radius: ${(props) => props.borderRadius || '7px'};
  background-color: ${(props) => props.backgroundColor || white};
  margin: ${(props) => props.margin || '0 0 30px'};
  height: ${(props) => props.height || 'auto'};
  cursor: ${(props) => props.cursor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const ButtonStyled = styled.button`
  ${btn};
  border: ${(props) => props.border};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${(props) => (props.rounded ? '50%' : props.radius || '4px')};
  padding: ${(props) => props.padding || '8px 12px'};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => (props.block ? '100%' : props.width)};
  border-color: ${(props) => props.borderColor};
  font-size: ${(props) => props.fontSize};
  ${(props) => btncolor[props.color]};
  background-color: ${(props) =>
    props.disabled ? '#bcbcbc' : props.backgroundColor};
  box-shadow: ${(props) =>
    props.shadow ? '2px 3px 6px rgba(0, 0, 0, 0.1)' : 'none'};
  font-family: ${content};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const ContentSection = styled.section`
  padding: ${(props) => props.padding || '2rem 0'};
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  margin: ${(props) => props.margin};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }

  @media (min-width: ${breakpoints[0]}) and (max-width: ${breakpoints[2]}) {
    ${(props) => props.mediumStyle}
  }
`

const PageTitle = styled.h1`
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '24px'};
  margin: ${(props) => props.margin || '0 0 30px'};
  color: ${(props) => props.color || blackpure};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};

  @media (max-width: ${breakpoints[0]}) {
    font-size: 30px;
    margin: 0 0 15px;
    ${(props) => props.mobileStyle}
  }
`

const Title = styled.h2`
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '48px'};
  margin: ${(props) => props.margin || '0 0 30px'};
  color: ${(props) => props.color || blackpure};
  font-family: ${title};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${breakpoints[0]}) {
    font-size: 30px;
    margin: 0 0 15px;
    ${(props) => props.mobileStyle}
  }
`

const Subtitle = styled.h5`
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '28px'};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color || blackpure};
  font-family: ${title};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Desc = styled.p`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || black};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  font-family: ${(props) => props.fontFamily || content};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  letter-spacing: ${(props) => props.letterSpacing};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Space = styled.div`
  margin: ${(props) => props.margin || '0 0 15px'};
`

export {
  btn,
  Relative,
  Span,
  Absolute,
  FormGroup,
  Flex,
  FlexCell,
  FlexCellShadow,
  InlineBlock,
  WhiteSpace,
  Text,
  Image,
  Separator,
  Icon,
  Fixed,
  Divider,
  BoxShadow,
  Card,
  ButtonStyled,
  ContentSection,
  PageTitle,
  Title,
  Subtitle,
  Desc,
  Space,
}
