import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { midtransENV, midtransURL } from '../../../../utils/apihost'
import { midtranspubkey } from '../../../../utils/key'
import master from '../../../../utils/master'
import PaymentMethod from '../../Checkout/components/PaymentMethod/PaymentMethod'
import Voucher from '../../Checkout/components/Voucher/Voucher'
import api from '../api'
import Total from './Right/Total'

const Right = ({
  data,
  forms,
  setForms,
  onChange,
  calculations,
  plasticDetails,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  useEffect(() => {
    let scriptTag = document.createElement('script')
    scriptTag.src = midtransURL
    scriptTag.setAttribute('id', 'midtrans-script')
    scriptTag.setAttribute('data-environment', midtransENV)
    scriptTag.setAttribute('data-client-key', midtranspubkey)
    document.body.appendChild(scriptTag)
    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [])

  const validate = (form) => {
    if (!form?.payment) {
      toast.error('Pembayaran harus dipilih')
      return false
    }
    if (
      master.paymentMap[form?.payment?.payment_method_id]?.type == 'cc' &&
      form?.cc_number?.split('-')?.join('')?.length < 16
    ) {
      toast.error('Nomor kartu kredit harus 16 angka')
      return false
    }
    if (
      master.paymentMap[form?.payment?.payment_method_id]?.type == 'cc' &&
      !form?.cc_period
    ) {
      toast.error('Masa berlaku kartu kredit harus diisi')
      return false
    }
    if (
      master.paymentMap[form?.payment?.payment_method_id]?.type == 'cc' &&
      form?.cc_cvv?.length != 3
    ) {
      toast.error('CVV kartu kredit harus 3 angka')
      return false
    }
    return true
  }

  const [state, submit] = useAsyncFn(async (data, forms, token) => {
    dispatch({ type: 'loading' })
    let type =
      data?.type?.id == -1
        ? data?.details?.operator_data_package_id
          ? 'paketdata'
          : 'pulsa'
        : 'biller'
    let res = await api.createRecharge(
      type,
      data?.details?.operator_data_package_id || '',
      data?.details?.operator_credit_package_id || '',
      data?.number || '',
      forms?.voucher?.id,
      forms?.voucher?.owner_id,
      data?.billing_id || '',
      data?.type?.product_code || '',
      forms?.payment?.payment_method_id,
      forms?.token_id || '',
      token,
    )

    dispatch({ type: 'unloading' })
    if (!res.success) {
      if (res.forbidden) logout()
      toast.error(res.message)
      return false
    }
    history.push(
      '/account/digital/transactions/details/' +
        res?.data?.digital_transaction_id +
        '?pay=true',
    )
  }, [])

  const getTokenId = async (details, forms, token) => {
    let cardData = {
      card_number: forms?.cc_number?.split('-')?.join(''),
      card_exp_month: forms?.cc_period?.substr(0, 2),
      card_exp_year: '20' + forms?.cc_period?.substr(3, 2),
      card_cvv: forms?.cc_cvv,
    }
    let options = {
      onSuccess: function (response) {
        details = { ...details, token_id: response.token_id }
        submit(details, forms, token)
      },
      onFailure: function (response) {
        toast.error(response?.status_message)
      },
    }
    window?.MidtransNew3ds?.getCardToken(cardData, options)
  }

  const presubmit = () => {
    if (!validate(forms)) return false
    if (master.paymentMap[forms?.payment]?.type == 'cc') {
      getTokenId(data, forms, token)
      return false
    }
    submit(data, forms, token)
  }

  return (
    <>
      <Voucher
        forms={forms}
        setForms={setForms}
        onChange={onChange}
        calculations={calculations}
        digital
        details={{
          package_id: data?.details?.operator_data_package_id || '',
          recharge_id: data?.details?.operator_credit_package_id || '',
          billing_id: data?.billing_id || '',
        }}
      />
      <PaymentMethod
        forms={forms}
        onChange={onChange}
        calculations={calculations}
        useCOD={false}
        type={data?.type?.id == -1 ? 2 : 1}
      />
      <Total
        forms={forms}
        calculations={calculations}
        plasticDetails={plasticDetails}
        presubmit={presubmit}
      />
    </>
  )
}

export default Right
