import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import React, { useEffect } from 'react'
import Geocode from 'react-geocode'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import './assets/css/font.css'
import './assets/scss/styles.scss'
import { colors } from './assets/styles/styles'
import CurrentLocation from './components/CurrentLocation/CurrentLocation'
import ModalLoading from './components/Spinner/ModalLoading'
import PageLoading from './components/Spinner/PageLoading'
import routes from './routes'
import { googleapikey } from './utils/key'

const Layout = React.lazy(() => import('./components/Layout/Layout'))

const pageloading = <PageLoading />

const App = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.loading)
  const ready = useSelector((state) => state.ready)

  const updateLocalData = (localStorageKey, reduxKey, value) => {
    dispatch({ type: 'update', [reduxKey]: value })
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({ data: value, last_update: moment() }),
    )
  }

  const fetchData = async () => {
    let login_data = localStorage.getItem('login_data')
    if (login_data) {
      login_data = JSON.parse(login_data)
    } else login_data = {}

    dispatch({
      type: 'update',
      ...login_data,
      authenticated: true,
      ready: true,
      updateLocalData,
    })
  }

  useEffect(() => {
    fetchData()
    Geocode.setApiKey(googleapikey)
  }, [])

  if (!ready) return <PageLoading />

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={pageloading}>
          <Switch>
            {routes.map((g, i) => (
              <Route
                key={i}
                path={g.path}
                name={g.name}
                exact={g.exact}
                render={(props) => {
                  return <Layout {...g} {...props} component={g.component} />
                }}
              />
            ))}
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>

      <ModalLoading open={loading} />

      <CurrentLocation />

      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            padding: '15px 20px',
          },
          success: {
            style: {
              background: colors.secondary,
              color: colors.white,
            },
          },
          error: {
            style: {
              background: colors.primary,
              color: colors.white,
            },
          },
        }}
      />
    </>
  )
}

export default App
