import React from 'react'
import DigitalTransactionDetails from './routes/Account/DigitalTransactionDetails/DigitalTransactionDetails'
import DigitalTransactions from './routes/Account/DigitalTransactions/DigitalTransactions'
import ForgotOTP from './routes/Authentication/ForgotPassword/ForgotOTP'
import ForgotPassword from './routes/Authentication/ForgotPassword/ForgotPassword'
import ResetPassword from './routes/Authentication/ForgotPassword/ResetPassword'
import PasswordlessLogin from './routes/Authentication/Login/PasswordlessLogin'
import RegisterOTP from './routes/Authentication/Register/RegisterOTP'
import DigitalCheckout from './routes/Cart/DigitalCheckout/DigitalCheckout'
import DigitalPayment from './routes/Cart/Payment/DigitalPayment'
import Payment from './routes/Cart/Payment/Payment'
import Digital from './routes/Digital/Digital/Digital'
import Voucher from './routes/Home/Voucher/Voucher'

const Home = React.lazy(() => import('./routes/Home/Home/Home'))
const Login = React.lazy(() => import('./routes/Authentication/Login/Login'))
const Register = React.lazy(() =>
  import('./routes/Authentication/Register/Register'),
)

const Cart = React.lazy(() => import('./routes/Cart/Cart/Cart'))
const Checkout = React.lazy(() => import('./routes/Cart/Checkout/Checkout'))

const Account = React.lazy(() => import('./routes/Account/Account/Account'))
const Address = React.lazy(() => import('./routes/Account/Address/Address'))
const Favorite = React.lazy(() => import('./routes/Account/Favorite/Favorite'))
const Transactions = React.lazy(() =>
  import('./routes/Account/Transactions/Transactions'),
)
const TransactionDetails = React.lazy(() =>
  import('./routes/Account/TransactionDetails/TransactionDetails'),
)
const Settings = React.lazy(() => import('./routes/Account/Settings/Settings'))

const ProductSearch = React.lazy(() =>
  import('./routes/Product/ProductSearch/ProductSearch'),
)
const ProductDetails = React.lazy(() =>
  import('./routes/Product/ProductDetails/ProductDetails'),
)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    exact: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    exact: true,
  },
  {
    path: '/pwl',
    name: 'PasswordlessLogin',
    component: PasswordlessLogin,
    exact: true,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    exact: true,
  },
  {
    path: '/register/otp',
    name: 'RegisterOTP',
    component: RegisterOTP,
    exact: true,
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/forgot/otp',
    name: 'ForgotOTP',
    component: ForgotOTP,
    exact: true,
  },
  {
    path: '/forgot/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    exact: true,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    exact: true,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    exact: true,
  },
  {
    path: '/account/address',
    name: 'Address',
    component: Address,
    exact: true,
  },
  {
    path: '/account/favorite',
    name: 'Favorite',
    component: Favorite,
    exact: true,
  },
  {
    path: '/account/transactions',
    name: 'Transactions',
    component: Transactions,
    exact: true,
  },
  {
    path: '/account/transactions/details/:id',
    name: 'TransactionDetails',
    component: TransactionDetails,
    exact: true,
  },
  {
    path: '/account/digital/transactions',
    name: 'DigitalTransactions',
    component: DigitalTransactions,
    exact: true,
  },
  {
    path: '/account/digital/transactions/details/:id',
    name: 'DigitalTransactionDetails',
    component: DigitalTransactionDetails,
    exact: true,
  },
  {
    path: '/account/settings',
    name: 'Settings',
    component: Settings,
    exact: true,
  },
  {
    path: '/product/search',
    name: 'ProductSearch',
    component: ProductSearch,
    exact: true,
  },
  {
    path: '/product/details/:id',
    name: 'ProductDetails',
    component: ProductDetails,
    exact: true,
  },
  {
    path: '/voucher',
    name: 'Voucher',
    component: Voucher,
    exact: true,
  },
  {
    path: '/digital',
    name: 'Digital',
    component: Digital,
    exact: true,
  },
  {
    path: '/digital/checkout',
    name: 'DigitalCheckout',
    component: DigitalCheckout,
    exact: true,
  },
  {
    path: '/payment/:id',
    name: 'Payment',
    component: Payment,
    exact: true,
  },
  {
    path: '/payment/digital/:id',
    name: 'DigitalPayment',
    component: DigitalPayment,
    exact: true,
  },
]

export default routes
