import React from 'react'
import Types from '../DigitalType'

const Left = ({ data, forms, onChange }) => {
  let Type = Types[data?.type?.product_code || -1]
  return (
    <>
      <Type data={data} />
    </>
  )
}

export default Left
