import classNames from 'classnames'
import React, { useState } from 'react'

const Select = ({
  label,
  id,
  name,
  options = [],
  placeholder,
  wrapperStyle,
  labelStyle,
  nameKey = 'name',
  valueKey = 'value',
  error,
  value,
  onChange,
  required,
  onFocus,
  onBlur,
  ...props
}) => {
  const [focused, setFocused] = useState(false)

  const isFocused = focused || value?.length > 0

  return (
    <div className="form-group" style={wrapperStyle}>
      <div className="input-group">
        <select
          {...props}
          id={id}
          name={name || id}
          value={value}
          onChange={(e) => {
            if (options[e.target.selectedIndex - 1])
              e.target.object = options[e.target.selectedIndex - 1]
            onChange(e)
          }}
          onFocus={() => {
            setFocused(true)
            onFocus && onFocus()
          }}
          onBlur={() => {
            setFocused(false)
            onBlur && onBlur()
          }}
          className="form-control o-input"
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((g, i) => (
            <option key={i} value={g[valueKey]}>
              {g[nameKey]}
            </option>
          ))}
        </select>
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
      {label && (
        <label htmlFor={id} className={classNames({ focused: isFocused })}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Select
