import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async checkBiller(product_code, bill_number, amount, token) {
    let form = JSON.stringify({ product_code, bill_number, amount })
    let responseJSON = await fetchPost(
      baseURL + '/digital/checkbiller',
      form,
      token,
    )
    return responseJSON
  },

  async checkProvider(prefix, token) {
    let form = JSON.stringify({ prefix })
    let responseJSON = await fetchPost(
      baseURL + '/digital/checknumber',
      form,
      token,
    )
    return responseJSON
  },
}
