import moment from 'moment'
import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'
import InfoList from '../../../../components/View/InfoList'
import { formatMoney } from '../../../../utils/functions'

const TelkomGroup = ({ data }) => {
  let details = []

  for (
    let i = 1;
    i <= parseInt(data?.details?.customerData?.jumlahBill || 1);
    i++
  ) {
    if (data?.details?.customerData[`billInfo${i}`]) {
      let found = data?.details?.customerData[`billInfo${i}`]
      let date = found?.nomorReferensi?.replace('A', '')
      let year = date.substring(0, 1)
      let month = date.substring(1, date.length)
      let curryear = moment().format('YY')
      curryear = curryear.substring(0, 1)
      let fyear = curryear + year
      let fmonth = moment(month, 'MM').format('MMM')
      let fdate = fmonth + fyear
      let obj = {
        ...found,
        date,
        year,
        month,
        fyear,
        fmonth,
        fdate,
        fulldate:
          moment(month, 'MM').format('MMMM') +
          ' ' +
          moment(fyear, 'YY').format('YYYY'),
      }
      details.push(obj)
    }
  }

  return (
    <>
      <CardInfo
        title={data?.type?.name}
        content={
          <>
            <Flex>
              <FlexCell>
                <Image
                  source={data?.type?.picture}
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FlexCell>
              <FlexCell padding="0 0 0 15px">
                <Text fontWeight="bold">{data?.customer_name}</Text>
                <Text>{data?.number}</Text>
                <Text>{`${details[details?.length - 1]?.fdate} - ${
                  details[0]?.fdate
                }`}</Text>
              </FlexCell>
            </Flex>
          </>
        }
      />

      <CardInfo
        title={'Detail Tagihan'}
        content={
          <>
            {details?.reverse().map((g, i) => (
              <InfoList
                key={i}
                title={g?.fulldate}
                content={` Rp${formatMoney(g?.nilaiTagihan)}`}
              />
            ))}
          </>
        }
      />
    </>
  )
}

export default TelkomGroup
