import classNames from 'classnames'
import React, { useState } from 'react'
import { formatMoney, numberOnly, phoneNumberOnly } from '../../utils/functions'

const Input = React.forwardRef(
  (
    {
      label,
      id,
      name,
      append,
      prepend,
      wrapperStyle,
      error,
      invalid,
      type = 'text',
      valueType = 'normal', // normal, phone, number
      onChange,
      style = {},
      value,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false)

    if (valueType == 'phone') prepend = '+62'

    if (valueType == 'currency') {
      value = formatMoney(value || '')
    }

    const isFocused = focused || value?.length > 0 || prepend

    return (
      <div className="form-group" style={wrapperStyle}>
        <div className="input-group">
          {prepend && <div className="o-input-prepend">{prepend}</div>}
          <input
            {...props}
            ref={ref}
            type={type}
            id={id}
            name={name || id}
            value={value}
            onChange={(e) => {
              if (valueType == 'phone') {
                e.target.value = phoneNumberOnly(e.target.value)
              }
              if (valueType == 'number') {
                e.target.value = numberOnly(e.target.value)
              }
              if (valueType == 'currency') {
                e.target.value = numberOnly(e.target.value)
                if (e.target.value != '')
                  e.target.value = parseInt(
                    e.target.value.split('.').join(''),
                  ).toString()
                else e.target.value = ''
              }
              onChange(e)
            }}
            onFocus={() => {
              setFocused(true)
              onFocus && onFocus()
            }}
            onBlur={() => {
              setFocused(false)
              onBlur && onBlur()
            }}
            className="form-control o-input"
            style={{ paddingLeft: valueType == 'phone' ? 33 : 0, ...style }}
          />
          {append && <div className="o-input-append">{append}</div>}
        </div>
        {error && <div className="invalid-feedback">{error}</div>}
        {label && (
          <label htmlFor={id} className={classNames({ focused: isFocused })}>
            {label}
          </label>
        )}
      </div>
    )
  },
)

export default Input
