import React, { useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { Card, Flex, Text } from '../../../../../assets/components/components'
import { colors } from '../../../../../assets/styles/styles'
import ModalVoucher from './ModalVoucher'

const Voucher = ({ forms, setForms, digital = false, details }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Card padding="20px">
        <Text fontWeight="bold" fontSize="16px" margin="0 0 10px">
          Promo
        </Text>

        <Flex
          justifyContent="space-between"
          padding="12px 10px 12px 15px"
          borderRadius="5px"
          cursor="pointer"
          backgroundColor={colors.secondary20}
          onClick={() => setModal(true)}
        >
          <Text fontSize="15px">
            {forms?.voucher ? (
              <Text color={colors.secondary}>
                {forms?.voucher?.name || '-'}
              </Text>
            ) : (
              <Text opacity="0.6" fontSize="15px" color={colors.secondary}>
                Pilih Voucher
              </Text>
            )}
          </Text>

          <FiChevronRight size={20} color={colors.secondary} />
        </Flex>
      </Card>

      <ModalVoucher
        open={modal}
        close={() => setModal(false)}
        active={forms?.voucher}
        setActive={(voucher) => setForms({ ...forms, voucher })}
        digital={digital}
        details={details}
      />
    </>
  )
}

export default Voucher
