import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'

const PLNPrepaid = ({ data }) => {
  return (
    <>
      <CardInfo
        title={data?.type?.name}
        content={
          <>
            <Flex>
              <FlexCell>
                <Image
                  source={data?.type?.picture}
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FlexCell>
              <FlexCell padding="0 0 0 15px">
                <Text fontWeight="bold">{data?.customer_name}</Text>
                <Text>{data?.number}</Text>
                <Text>{data?.details?.customerData?.power} VA</Text>
              </FlexCell>
            </Flex>
          </>
        }
      />
    </>
  )
}

export default PLNPrepaid
