import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost } from '../../../utils/fetch'

export default {
  async getTransactionFee(token) {
    let responseJSON = await fetchGet(
      baseURL + `/digital/transactionfee`,
      token,
    )
    return responseJSON
  },

  async createRecharge(
    type,
    operator_data_package_id,
    operator_credit_package_id,
    phone_number,
    voucher_id,
    owner_id,
    billing_log_id,
    product_code,
    payment_method_id,
    token_id,
    token,
  ) {
    let form = JSON.stringify({
      type,
      operator_data_package_id,
      operator_credit_package_id,
      phone_number,
      voucher_id,
      owner_id,
      billing_log_id,
      product_code,
      bill_number: phone_number,
      payment_method_id,
      token_id,
    })
    let responseJSON = await fetchPost(baseURL + '/digital/create', form, token)
    return responseJSON
  },
}
