import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async login(phone_number, password) {
    let form = JSON.stringify({
      phone_number,
      password,
    })
    let responseJSON = await fetchPost(
      baseURL + '/login',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async loginSocial(Media, UserId, UserToken) {
    let form = JSON.stringify({ Media, UserId, UserToken })
    let responseJSON = await fetchPost(
      baseURL + '/processSocialLogin',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async passwordlessLogin(phone_number, token) {
    let form = JSON.stringify({
      phone_number,
      token,
    })
    let responseJSON = await fetchPost(
      baseURL + '/passwordlesslogin',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },
}
