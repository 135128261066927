import React from 'react'
import { Card, Separator, Text } from '../../assets/components/components'

const CardInfo = ({ title, value, content }) => {
  return (
    <Card padding="20px">
      <Text fontWeight="bold" fontSize="16px">
        {title}
      </Text>

      <Separator margin="15px 0" />

      {content || <Text>{value}</Text>}
    </Card>
  )
}

export default CardInfo
