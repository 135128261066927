import moment from 'moment'
import React from 'react'
import { Text } from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'
import { formatMoney } from '../../../../utils/functions'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const PLNPostpaid = ({ data, log }) => {
  let sm = log?.customerData?.info?.split('|') || ['', '']
  if (sm.length > 1) {
    sm = sm[1]?.split(':')
  }
  if (sm.length > 1) sm = sm[1] || '-'

  let period = '-'
  if (log?.customerData?.billInfo?.length > 0) {
    let first = moment(
      parseInt(log?.customerData?.billInfo[0]?.period).toString(),
      'YYYYMM',
    ).format('MMMYY')
    let last = moment(
      parseInt(
        log?.customerData?.billInfo[log?.customerData?.billInfo?.length - 1]
          ?.period,
      ).toString(),
      'YYYYMM',
    ).format('MMMYY')
    period = first + ' - ' + last
  }
  return (
    <>
      <ProductDetails
        name={data?.product_name}
        desc={`
				${log?.customerData?.customerName} (
				${log?.customerData?.customerId})`}
      />

      <CardInfo
        title="Detail Tagihan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(
                log?.timeStampServer,
                'DD-MM-YYYY HH:mm:ss',
              ).format('DD MMM YYYY - HH:mm')}
            />
            <InfoList
              title="Nama"
              content={`${log?.customerData?.customerName}`}
            />
            <InfoList
              title="ID Pelanggan"
              content={`${log?.customerData?.customerId}`}
            />
            <InfoList
              title="Tarif / Daya"
              content={`${log?.customerData?.subscriberSegmen}/${parseInt(
                log?.customerData?.power || 0,
              )} VA`}
            />
            <InfoList title="Bulan / Tahun" content={period} />
            <InfoList title="Stand Meter" content={`${sm}`} />
            <InfoList
              title="Rp Tag PLN"
              content={'Rp ' + formatMoney(parseInt(log?.amount || 0))}
            />
            {log?.customerData?.inqReff ? (
              <InfoList
                title="Nomor Referensi"
                content={log?.customerData?.inqReff}
                withCopy
              />
            ) : null}
          </>
        }
      />

      <Text textAlign="center" fontWeight="bold" margin="15px 0 0">
        PLN menyatakan struk ini sebagai bukti pembayaran yang sah
      </Text>
    </>
  )
}

export default PLNPostpaid
