import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import {
  Flex,
  FlexCell,
  Relative,
  Span,
  Text,
} from '../../../assets/components/components'
import { colors } from '../../../assets/styles/styles'
import Button from '../../../components/Button/Button'
import InputNormal from '../../../components/Input/InputNormal'
import { deepCopy } from '../../../utils/functions'
import api from './api'

const number = 60
const baseOtp = ['', '', '', '']

const ForgotOTP = ({ location }) => {
  const [otp, setOtp] = useState(deepCopy(baseOtp))
  const [error, setError] = useState('')
  const [counter, setCounter] = useState(number)
  const [fetching, setFetching] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const otpref = useRef([null, null, null, null, null, null])

  const onChangeInput = (index, e) => {
    let value = e.target.value
    let newotp = [...otp]
    newotp[index] = value
    setOtp(newotp)
    if (value.length == 1) {
      if (index != otp.length - 1) otpref.current[index + 1].focus()
    }
  }

  const [statefetch, fetch] = useAsyncFn(async (phone_number) => {
    dispatch({ type: 'loading' })
    let res = await api.forgotPassword(phone_number)

    dispatch({ type: 'unloading' })
    if (!res.success) {
      toast.error(res.message)
      return false
    }
  }, [])

  const [state, submit] = useAsyncFn(async (phone_number, customer_id, otp) => {
    dispatch({ type: 'loading' })
    let res = await api.checkForgotPasswordToken(phone_number, customer_id, otp)

    dispatch({ type: 'unloading' })
    if (!res.success) {
      toast.error(res.message)
      return false
    }

    toast.success(res.message)
    history.push('/forgot/reset', {
      phone_number,
      customer_id,
      otp,
    })
  }, [])

  const refetch = () => {
    fetch && fetch(location?.state?.phone_number)
    setOtp(deepCopy(baseOtp))
    setCounter(number)
    otpref?.current[0]?.focus()
  }

  useEffect(() => {
    if (!counter) return
    const interval = setInterval(() => {
      setCounter(counter - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [counter])

  return (
    <section className="register">
      <Container>
        <Row>
          <Col xs="12" sm="3"></Col>
          <Col xs="12" sm="6">
            <Relative
              padding="25px"
              border={`1px solid ${colors.border}`}
              borderRadius="5px"
            >
              <Text
                fontWeight="bold"
                textAlign="center"
                fontSize="18px"
                margin="0 0 10px"
              >
                Kode Verifikasi
              </Text>
              <Text textAlign="center" margin="0 0 30px">
                Kami telah mengirimkan kode OTP ke nomor ponsel Anda. Masukkan 4
                digit kode yang Anda terima di bawah ini.
              </Text>

              <Flex justifyContent="center" margin="30px 0 15px">
                {otp.map((g, i) => (
                  <FlexCell key={i} padding="0 15px 0 0">
                    <InputNormal
                      ref={(e) => (otpref.current[i] = e)}
                      valueType="number"
                      value={g}
                      className="otp-input"
                      onChange={(e) => onChangeInput(i, e)}
                      maxLength={1}
                      onKeyDown={(e) => {
                        if (e.keyCode === 8) {
                          if (e.target.value == '' && i > 0)
                            otpref.current[i - 1].focus()
                        }
                      }}
                    />
                  </FlexCell>
                ))}
              </Flex>

              <Button
                color="primary"
                block
                onClick={() => {
                  submit(
                    location?.state?.phone_number,
                    location?.state.customer_id,
                    otp?.join(''),
                  )
                }}
              >
                Lanjutkan
              </Button>

              <Text textAlign="center" margin="15px 0 0">
                Tidak menerima kode?{' '}
                {counter == 0 ? (
                  <Span
                    fontWeight="bold"
                    color={colors.primary}
                    cursor="pointer"
                    onClick={refetch}
                  >
                    Kirim ulang
                  </Span>
                ) : (
                  `Kirim ulang dalam ${counter} detik`
                )}
              </Text>
            </Relative>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ForgotOTP
