import moment from 'moment'
import React from 'react'
import CardInfo from '../../../../components/View/CardInfo'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const BPJS = ({ data, log }) => {
  let id = log?.customerData?.customerId ? log?.customerData?.customerId : '-'
  return (
    <>
      <ProductDetails
        name={data?.product_name}
        desc={
          id +
          (log?.customerData?.numberOfMonth
            ? log?.customerData?.numberOfMonth
            : '')
        }
      />

      <CardInfo
        title="Detail Tagihan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(
                log?.timeStampServer,
                'DD-MM-YYYY HH:mm:ss',
              ).format('DD MMM YYYY - HH:mm')}
            />

            <InfoList title="Nomor BPJS" content={id} />
            <InfoList
              title="Nama (Jumlah Jiwa)"
              content={
                log?.customerData
                  ? `${log?.customerData?.billInfo1?.customerName} (${parseInt(
                      log?.customerData?.memberTotal || 0,
                    )} Jiwa)`
                  : '-'
              }
            />
            <InfoList
              title="Jumlah Bulan"
              content={
                log?.customerData
                  ? log?.customerData?.numberOfMonth + ' Bulan'
                  : '-'
              }
            />
            {log?.customerData?.reffNo ? (
              <InfoList
                title="Nomor Referensi"
                content={log?.customerData?.reffNo}
                withCopy
              />
            ) : null}
          </>
        }
      />
    </>
  )
}

export default BPJS
