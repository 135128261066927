import React from 'react'
import { MdError } from 'react-icons/md'
import { Flex, Image, Text } from '../../assets/components/components'
import images from '../../assets/images'
import { colors } from '../../assets/styles/styles'
import Spinner from '../Spinner/Spinner'

const FetchHandler = ({
  loading,
  error,
  content,
  refetch,
  size = 30,
  margin = '14vh 0',
  showEmpty = false,
  emptyText = 'Belum ada data',
  emptyIcon = (
    <Image
      src={images.figure.cart}
      width="100px"
      height="auto"
      objectFit="contain"
    />
  ),
  renderEmpty,
}) => {
  if (loading) {
    return (
      <Flex justifyContent="center" column margin={margin}>
        <Spinner name="pulse" size={size} margin="0 auto" />
      </Flex>
    )
  }
  if (error) {
    return (
      <Flex justifyContent="center" column margin={margin}>
        <MdError
          color={colors?.primary}
          size={size + 60}
          style={{ opacity: 0.7 }}
        />
        <Text style={{ textAlign: 'center' }} margin="10px 0 0">
          Gagal memuat data,{' '}
          <span
            style={{ color: colors?.primary, cursor: 'pointer' }}
            onClick={refetch}
          >
            coba lagi
          </span>
        </Text>
      </Flex>
    )
  }
  if (showEmpty) {
    return (
      renderEmpty || (
        <Flex justifyContent="center" column margin={margin}>
          {emptyIcon}
          <Text textAlign="center" margin="20px 0 0">
            {emptyText}
          </Text>
        </Flex>
      )
    )
  }
  return content
}

export default FetchHandler
