import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import FetchHandler from '../../../components/State/FetchHandler'
import apiHome from '../../Home/Home/api'
import Left from './components/Left'
import Right from './components/Right'

const inputs = {
  type: '', // type of product -> pulsa, garuda, etc
  number: '', // number field
  recharge_or_package_details: '', // selected product for recharge or package data
  typelist: [],
  amount: '', // amount used if has nominal, e.g pln
  month: '', // month to be paid, for bpjs
}

const errs = {
  type: '',
  number: '',
  recharge_or_package_details: '',
  typelist: [],
  amount: '',
  month: '',
}

const Digital = ({ match }) => {
  const [forms, setForms] = useState({ ...inputs })
  const [errors, setErrors] = useState({ ...errs })

  const [errordata, setErrordata] = useState(false)
  const [activeData, setActiveData] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const activeStore = useSelector((state) => state.activeStore)
  const threshold = useSelector((state) => state.threshold)
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)
  const billers = useSelector((state) => state.billers)

  const onChange = (e) => {
    const value =
      e.target.type == 'checkbox' ? e.target.checked : e.target.value
    if (errors[e.target.name]) setErrors({ ...errors, [e.target.name]: '' })
    setForms({ ...forms, [e.target.name]: value })
  }

  const [state, fetch] = useAsyncFn(async (store_id, token) => {
    setErrordata(false)
    const res = await apiHome.getHomepage(store_id, token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let billers = res.data?.biller || []
    dispatch({ type: 'update', billers })
  }, [])

  const refetch = () => {
    fetch(activeStore?.store_id || '', token)
  }

  useEffect(() => {
    if (!billers || billers?.length == 0) refetch()
  }, [])

  return (
    <section className="content">
      <FetchHandler
        loading={state.loading}
        error={errordata}
        refetch={refetch}
        content={
          <>
            <Container>
              <Row>
                <Col xs="12" sm="6">
                  <Left forms={forms} setForms={setForms} />
                </Col>
                <Col xs="12" sm="1" />
                <Col xs="12" sm="5">
                  {forms?.type ? (
                    <Right
                      forms={forms}
                      setForms={setForms}
                      errors={errors}
                      onChange={onChange}
                    />
                  ) : null}
                </Col>
              </Row>
            </Container>
          </>
        }
      />
    </section>
  )
}

export default Digital
