import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import images from '../../../../assets/images'
import CardInfo from '../../../../components/View/CardInfo'

const ProductDetails = ({ icon, name, desc }) => {
  return (
    <CardInfo
      title="Produk"
      content={
        <Flex>
          <FlexCell>
            <Image
              src={icon || images.icons.billing}
              width="25px"
              height="auto"
            />
          </FlexCell>
          <FlexCell padding="0 0 0 15px">
            <Text fontWeight="bold">{name}</Text>
            <Text>{desc}</Text>
          </FlexCell>
        </Flex>
      }
    />
  )
}

export default ProductDetails
