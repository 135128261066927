import moment from 'moment'
import React from 'react'
import { Flex, Text } from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'
import { formatMoney } from '../../../../utils/functions'
import { statuses } from '../../DigitalTransactions/components/Statuses'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const TelkomGroup = ({ data, log }) => {
  let details = []

  if (log?.customerData?.jumlahBill) {
    for (let i = 1; i <= parseInt(log?.customerData?.jumlahBill || 1); i++) {
      if (log?.customerData[`billInfo${i}`]) {
        let found = log?.customerData[`billInfo${i}`]
        let date = found?.nomorReferensi?.replace('A', '')
        let year = date.substring(0, 1)
        let month = date.substring(1, date.length)
        let curryear = moment().format('YY')
        curryear = curryear.substring(0, 1)
        let fyear = curryear + year
        let fmonth = moment(month, 'MM').format('MMM')
        let fdate = fmonth + fyear
        let obj = {
          ...found,
          date,
          year,
          month,
          fyear,
          fmonth,
          fdate,
          fulldate:
            moment(month, 'MM').format('MMMM') +
            ' ' +
            moment(fyear, 'YY').format('YYYY'),
        }
        details.push(obj)
      }
    }
  }

  return (
    <>
      <ProductDetails
        name={data?.product_name}
        desc={`${log?.customerData?.customerName} (
				${log?.customerData?.customerId})`}
      />

      <CardInfo
        title="Detail Tagihan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(
                log?.timeStampServer,
                'DD-MM-YYYY HH:mm:ss',
              ).format('DD MMM YYYY - HH:mm')}
            />
            <InfoList
              title="Nama"
              content={log?.customerData?.customerName || '-'}
            />
            <InfoList
              title="ID Pelanggan"
              content={log?.customerData?.customerId || '-'}
            />
            <InfoList
              title="Periode Bayar"
              content={`${details[details?.length - 1]?.fdate || ''} - ${
                details[0]?.fdate || ''
              }`}
            />
            <InfoList
              title="Status"
              content={statuses[data?.status]?.text || '-'}
            />

            {log?.traxId ? (
              <InfoList
                title="Nomor Referensi"
                content={log?.traxId}
                withCopy
              />
            ) : null}

            <Text>Detail</Text>
            {details?.length > 0 ? (
              details?.reverse().map((g, i) => (
                <Flex
                  justifyContent="space-between"
                  key={i}
                  style={{ marginTop: i == 0 ? 0 : 10 }}
                >
                  <Text fontWeight="bold">{g?.fulldate}</Text>
                  <Text>Rp {formatMoney(g?.nilaiTagihan)}</Text>
                </Flex>
              ))
            ) : (
              <Text>-</Text>
            )}
          </>
        }
      />
    </>
  )
}

export default TelkomGroup
