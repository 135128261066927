import React, { useEffect, useRef, useState } from 'react'
import Geocode from 'react-geocode'
import { useDispatch, useSelector } from 'react-redux'
import { Image, Relative, Span, Text } from '../../assets/components/components'
import images from '../../assets/images'
import { colors } from '../../assets/styles/styles'
import { checkLocalData } from '../../utils/functions'
import master from '../../utils/master'
import Spinner from '../Spinner/Spinner'
import api from './api'

const CurrentLocation = () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [currentLocation, setCurrentLocation] = useState('')

  const timeoutRef = useRef(null)

  const dispatch = useDispatch()
  const activeAddress = useSelector((state) => state.activeAddress)
  const updateLocalData = useSelector((state) => state.updateLocalData)
  const token = useSelector((state) => state.token)

  const getLocation = async () => {
    setShow(true)
    setError(false)
    setLoading(true)

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        let currentLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          ...master?.locationDelta,
        }

        setCurrentLocation(currentLocation)
        fetch(currentLocation)
      },
      (err) => {
        setError(true)
        setLoading(false)
      },
    )
  }

  const fetch = async (location = currentLocation) => {
    const resAddress = await Geocode.fromLatLng(
      location.latitude,
      location.longitude,
    )
    if (!resAddress) {
      setError(true)
      setLoading(false)
      return false
    }
    const address = resAddress.results[0]?.formatted_address || ''

    const res = await api.findNearbyAddress(
      location?.latitude,
      location?.longitude,
      token,
    )
    if (!res.success) {
      setError(true)
      setLoading(false)
      return false
    }

    let active_location = {
      ...location,
      address,
      map_address: address,
    }

    let nearest_address = res?.data?.nearest_address || ''
    let nearest_store = nearest_address?.neareststore || ''
    if (!nearest_address) nearest_address = active_location
    setData(active_location, nearest_address, nearest_store)
    updateLocalData(
      'last_updated_location',
      'last_updated_location',
      active_location,
    ) // to record the last time this modal showed up

    setLoading(false)
    close()
  }

  const close = () => {
    if (timeoutRef?.current) clearTimeout(timeoutRef?.current)
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null
      setShow(false)
    }, 1500)
  }

  const checkData = async () => {
    let res = await checkLocalData('last_updated_location')
    if (!res?.shouldUpdate && res?.data) {
      let current_location = await checkLocalData('current_location')
      let address = await checkLocalData('active_address_data')
      let store = await checkLocalData('active_store_data')
      setData(current_location?.data, address?.data, store?.data)
      return false
    }
    getLocation()
  }

  const setData = (current_location, nearest_address, nearest_store) => {
    updateLocalData &&
      updateLocalData('current_location', 'currentLocation', current_location)
    updateLocalData &&
      updateLocalData('active_address_data', 'activeAddress', nearest_address)
    if (nearest_store && nearest_store?.iud_status != 'd') {
      updateLocalData &&
        updateLocalData('active_store_data', 'activeStore', nearest_store)
    } else dispatch({ type: 'update', refetchStores: true })
  }

  useEffect(() => {
    checkData()
  }, [])

  if (!show) return null

  return (
    <div className="o-loading">
      <Image
        src={images.logo?.vertical}
        className="logo-shadow"
        alt="Logo Alfamidi"
      />
      <Relative margin="-15px 0 30px">
        <Text textAlign="center" margin="0 0 15px">
          Mengirim ke
        </Text>
        {loading ? (
          <Spinner name="pulse" size={24} margin="0 auto" />
        ) : error ? (
          <Text>
            Gagal mendapatkan lokasi,{' '}
            <Span
              color={colors.secondary}
              cursor="pointer"
              onClick={getLocation}
            >
              coba lagi
            </Span>
          </Text>
        ) : (
          <Text fontWeight="bold" fontSize="18px" textAlign="center">
            {activeAddress?.name ? `${activeAddress?.name} - ` : ''}
            {activeAddress?.map_address}
          </Text>
        )}
      </Relative>
      {/* <div className="o-loading-progress">
        <div className="fill"></div>
      </div> */}
    </div>
  )
}

export default CurrentLocation
