import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import Tabs from '../../../components/Tabs/Tabs'
import api from './api'
import Data from './components/Data'

const Voucher = ({ location }) => {
  const [type, setType] = useState(1)
  const [errordata, setErrordata] = useState(false)
  const [summary, setSummary] = useState({
    type: 1,
    // datestart: moment(),
    // dateend: moment(),
    limit: 20,
    current_page: 1,
    total_data: 0,
    total_page: 1,
  })
  const [data, setData] = useState([])
  const [checkedall, setCheckedall] = useState(true)
  const [activeData, setActiveData] = useState('')

  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, fetch] = useAsyncFn(async (type, token) => {
    setErrordata(false)
    let res = ''
    if (type == 1) res = await api.getOnlineVoucher('', token)
    else res = await api.getOfflineVoucher('', token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let data = res.data || []
    setData(data)
  }, [])

  const refetch = (type = 1) => {
    setType(type)
    fetch(type, token)
  }

  useEffect(() => {
    refetch()
  }, [])

  const Component = (
    <Data
      data={data}
      loading={state.loading}
      error={errordata}
      refetch={refetch}
      online={type == 1}
    />
  )
  return (
    <section className="content">
      <Container>
        <Row>
          <Col xs="12" sm="3"></Col>

          <Col xs="12" sm="6">
            <Tabs
              list={[
                {
                  name: 'Voucher Online',
                  content: Component,
                  onClick: () => refetch(1),
                },
                {
                  name: 'Voucher Offline',
                  content: Component,
                  onClick: () => refetch(2),
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Voucher
