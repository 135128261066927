import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async resendOTP(id) {
    let form = JSON.stringify({})
    let responseJSON = await fetchPost(
      baseURL + '/resendotp/' + id,
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async validateOTP(otp_code, customer_id) {
    let form = JSON.stringify({ otp_code, customer_id })
    let responseJSON = await fetchPost(
      baseURL + '/validateotp',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resendConfirmationEmail(Email) {
    let form = JSON.stringify({ Email })
    let responseJSON = await fetchPost(
      baseURL + '/resendConfirmationEmail',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async forgotPassword(phone_number) {
    let form = JSON.stringify({ phone_number })
    let responseJSON = await fetchPost(
      baseURL + '/forgotPassword',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async checkForgotPasswordToken(phone_number, customer_id, Token) {
    let form = JSON.stringify({ phone_number, customer_id, Token })
    let responseJSON = await fetchPost(
      baseURL + '/checkForgotPasswordToken',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resetPassword(phone_number, Token, Password, Konfirmasi, customer_id) {
    let form = JSON.stringify({
      phone_number,
      Token,
      Password,
      Konfirmasi,
      customer_id,
    })
    let responseJSON = await fetchPost(
      baseURL + '/resetPassword',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },
}
