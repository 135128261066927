import moment from 'moment'
import React from 'react'
import { Text } from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const Garuda = ({ data, log, inqlog }) => {
  return (
    <>
      <ProductDetails
        name={data?.product_name}
        desc={inqlog?.customerData?.passanger_name}
      />

      <CardInfo
        title="Info Penerbangan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(
                log?.timeStampServer,
                'DD-MM-YYYY HH:mm:ss',
              ).format('DD MMM YYYY - HH:mm')}
            />
            <InfoList
              title="Nama"
              content={`${inqlog?.customerData?.passanger_name || '-'}`}
            />
            <InfoList
              title="Kode Pembayaran"
              content={log?.customerData?.nomor_pembayaran}
              withCopy
            />
            <InfoList
              title="Kode Booking"
              content={`${inqlog?.customerData?.pnr_code || '-'}`}
            />
            <InfoList
              title="Info Penerbangan"
              content={`${log?.customerData?.carrier} ${
                log?.customerData?.flight_number
              } ${log?.customerData?.From}-${log?.customerData?.to} ${moment(
                log?.customerData?.hari_bulan_keberangkatan,
                'DDMM',
              ).format('DD/MM')} ${moment(
                log?.customerData?.jam_keberangkatan,
                'HHmm',
              ).format('HH:mm')}`}
            />
            {log?.traxId ? (
              <InfoList
                title="Nomor Referensi"
                content={log?.traxId}
                withCopy
              />
            ) : null}
          </>
        }
      />

      <Text textAlign="center" fontWeight="bold" margin="15px 0 0">
        Apabila tidak menerima email e-ticket dalam 30 menit hubungi call center
        Garuda Indonesia 021-22519999 / 0804-1-807-807
      </Text>
    </>
  )
}

export default Garuda
