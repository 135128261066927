import React from 'react'
import { Relative } from '../../assets/components/components'
import { colors } from '../../assets/styles/styles'

const ModalList = ({ active, disabled, onClick, children }) => {
  return (
    <Relative
      padding="15px"
      margin="0 0 15px"
      borderRadius="5px"
      cursor="pointer"
      border={`1px solid ${active ? colors.secondary : colors.border}`}
      opacity={disabled ? '0.4' : '1'}
      backgroundColor={active ? colors.secondary10 : ''}
      onClick={() => {
        if (disabled) return false
        onClick && onClick()
      }}
    >
      {children}
    </Relative>
  )
}

export default ModalList
