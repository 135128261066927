import moment from 'moment'
import React from 'react'
import CardInfo from '../../../../components/View/CardInfo'
import { formatMoney } from '../../../../utils/functions'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const PLNPrepaid = ({ data, log }) => {
  return (
    <>
      <ProductDetails
        name={data?.product_name}
        desc={`
				${log?.customerData?.customerName} (
				${log?.customerData?.customerId})`}
      />

      <CardInfo
        title="Detail Tagihan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(
                log?.timeStampServer,
                'DD-MM-YYYY HH:mm:ss',
              ).format('DD MMM YYYY - HH:mm')}
            />
            <InfoList
              title="Nama"
              content={`${log?.customerData?.customerName}`}
            />
            <InfoList
              title="ID Pelanggan"
              content={log?.customerData?.customerId}
            />
            <InfoList title="No Meter" content={log?.customerData?.meterId} />
            <InfoList
              title="Tarif / Daya"
              content={`${log?.customerData?.subscriberSegmen}/${parseInt(
                log?.customerData?.power || 0,
              )} VA`}
            />
            <InfoList
              title="Harga Token"
              content={
                'Rp ' +
                formatMoney(parseInt(log?.customerData?.electricityBill || 0))
              }
            />
            <InfoList
              title="Materai"
              content={
                'Rp ' + formatMoney(parseInt(log?.customerData?.materai || 0))
              }
            />
            <InfoList
              title="PPn"
              content={
                'Rp ' + formatMoney(parseInt(log?.customerData?.ppn || 0))
              }
            />
            <InfoList
              title="PPj"
              content={
                'Rp ' + formatMoney(parseInt(log?.customerData?.ppj || 0))
              }
            />
            <InfoList
              title="Fee"
              content={'Rp ' + formatMoney(log?.feeAmount || 0)}
            />
            <InfoList title="Angsuran" content={log?.customerData?.credit} />
            <InfoList
              title="Rp Stroom Token"
              content={log?.customerData?.electricityPrice}
            />
            <InfoList
              title="Jumlah Kwh"
              content={log?.customerData?.numberOfKwh || '-'}
            />
            <InfoList
              title="Nomor Token"
              content={log?.customerData?.serialNumber || '-'}
              withCopy={log?.customerData?.serialNumber ? true : false}
            />
            {log?.customerData?.swReferenceNumber ? (
              <InfoList
                title="Nomor Referensi"
                content={log?.customerData?.swReferenceNumber}
                withCopy
              />
            ) : null}
          </>
        }
      />
    </>
  )
}

export default PLNPrepaid
