import React from 'react'
import { MdClose } from 'react-icons/md'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ModalFormTemplate = ({ open, close, title, content, size = 'md' }) => {
  return (
    <Modal isOpen={open} toggle={close} centered fade={false} size={size}>
      <ModalHeader
        className="modal-header"
        toggle={close}
        close={<MdClose className="modal-close" size={24} onClick={close} />}
      >
        {title}
      </ModalHeader>
      <ModalBody>{content}</ModalBody>
    </Modal>
  )
}

export default ModalFormTemplate
