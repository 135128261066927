import React from 'react'
import { Col, Row } from 'reactstrap'
import { Relative, Text } from '../../../../../assets/components/components'
import { colors } from '../../../../../assets/styles/styles'
import { formatMoney } from '../../../../../utils/functions'

const List = ({ data, forms, setForms, type = 'recharge' }) => {
  let key =
    type == 'recharge'
      ? 'operator_credit_package_id'
      : 'operator_data_package_id'
  return (
    <Relative padding="15px" style={{ maxHeight: 400, overflow: 'auto' }}>
      <Row>
        {data?.map((g, i) => {
          let active =
            forms?.recharge_or_package_details &&
            forms?.recharge_or_package_details[key] == g[key]
          return (
            <Col key={i} xs="12" sm={type == 'recharge' ? '6' : '12'}>
              <Relative
                padding="12px 15px"
                margin="15px 0 0"
                borderRadius="5px"
                cursor="pointer"
                backgroundColor={active ? '#EFF3F8' : colors.white}
                border={`1px solid ${active ? colors.secondary : '#999'} `}
                onClick={() =>
                  setForms({ ...forms, recharge_or_package_details: g })
                }
              >
                {type == 'recharge' ? (
                  <Text textAlign="center">Rp{formatMoney(g.nominal)}</Text>
                ) : (
                  <>
                    <Text>{g.description}</Text>
                    <Text fontWeight="bold">
                      Rp {formatMoney(g.current_price)}
                    </Text>
                  </>
                )}
              </Relative>
            </Col>
          )
        })}
      </Row>
    </Relative>
  )
}

export default List
