import React, { useEffect, useState } from 'react'
import {
  Card,
  Flex,
  FlexCell,
  Separator,
  Text,
} from '../../../../assets/components/components'
import { formatMoney } from '../../../../utils/functions'

const Total = ({ data, isDigital = false }) => {
  const [countItem, setCountItem] = useState(0)

  useEffect(() => {
    let total = 0
    data?.deliveries?.forEach((g) => {
      g?.detail?.forEach((h) => {
        total += parseInt(h?.qty || 0)
      })
    })
    setCountItem(total)
  }, [data])

  return (
    <Card padding="20px">
      <Text fontWeight="bold" fontSize="17px" margin="0 0 15px">
        Total Belanja
      </Text>

      {!isDigital && (
        <Flex justifyContent="space-between">
          <FlexCell>
            <Text>Jumlah Item</Text>
          </FlexCell>

          <FlexCell>
            <Text>{countItem || 0}</Text>
          </FlexCell>
        </Flex>
      )}

      <Separator margin="15px 0" />

      <Flex justifyContent="space-between">
        <FlexCell>
          <Text>Total</Text>
        </FlexCell>

        <FlexCell>
          <Text fontWeight="bold" fontSize="17px">
            Rp
            {formatMoney(data?.total_price - (data?.delivery_discount || 0))}
          </Text>
        </FlexCell>
      </Flex>
    </Card>
  )
}

export default Total
