import moment from 'moment'
import React from 'react'
import images from '../../../../assets/images'
import CardInfo from '../../../../components/View/CardInfo'
import InfoList from '../components/InfoList'
import ProductDetails from '../components/ProductDetails'

const Pulsa = ({ data }) => {
  return (
    <>
      <ProductDetails
        icon={images.icons.rechargeblue}
        name={data?.fullname}
        desc={data?.phone_number}
      />

      <CardInfo
        title="Detail Tagihan"
        content={
          <>
            <InfoList
              title="Tanggal Transaksi"
              content={moment(data?.created_at).format('DD MMM YYYY - HH:mm')}
            />
            {data?.reference_number ? (
              <InfoList
                title="Nomor Referensi"
                content={data?.reference_number}
                withCopy
              />
            ) : null}
          </>
        }
      />
    </>
  )
}

export default Pulsa
