import classNames from 'classnames'
import React, { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { colors } from '../../assets/styles/styles'

const InputPassword = React.forwardRef(
  (
    {
      label,
      id,
      name,
      prepend,
      wrapperStyle,
      error,
      invalid,
      type = 'text',
      onChange,
      style,
      value,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false)
    const [show, setShow] = useState(false)

    const isFocused = (focused || value?.length > 0) && !prepend

    return (
      <div className="form-group" style={wrapperStyle}>
        <div className="input-group">
          {prepend && <div className="o-input-prepend">{prepend}</div>}
          <input
            {...props}
            ref={ref}
            type={show ? 'text' : 'password'}
            id={id}
            name={name || id}
            value={value}
            onChange={onChange}
            onFocus={() => {
              setFocused(true)
              onFocus && onFocus()
            }}
            onBlur={() => {
              setFocused(false)
              onBlur && onBlur()
            }}
            className="form-control o-input"
            style={style}
          />
          <div className="o-input-append">
            {show ? (
              <AiOutlineEyeInvisible
                color={colors?.grey}
                size={20}
                onClick={() => setShow(false)}
                cursor="pointer"
              />
            ) : (
              <AiOutlineEye
                color={colors?.grey}
                size={20}
                onClick={() => setShow(true)}
                cursor="pointer"
              />
            )}
          </div>
        </div>
        {error && <div className="invalid-feedback">{error}</div>}
        {label && (
          <label htmlFor={id} className={classNames({ focused: isFocused })}>
            {label}
          </label>
        )}
      </div>
    )
  },
)

export default InputPassword
