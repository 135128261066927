import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import { Relative } from '../../../assets/components/components'
import FetchHandler from '../../../components/State/FetchHandler'
import api from './api'
import Left from './components/Left'
import Right from './components/Right'

const inputs = {
  voucher: '',
  payment: '',

  // cc data
  cc_number: '',
  cc_period: '',
  cc_cvv: '',
}

const DigitalCheckout = ({ location, match }) => {
  const [errordata, setErrordata] = useState(false)
  const [data, setData] = useState('')
  const [forms, setForms] = useState({ ...inputs })
  const [calculations, setCalculations] = useState({
    total: 0,
  })

  const history = useHistory()
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const onChange = (e) => {
    setForms({ ...forms, [e.name]: e.value })
  }

  const [state, fetch] = useAsyncFn(async (data, token) => {
    setErrordata(false)
    const res = await api.getTransactionFee(token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }
    let servicefee = parseInt(res.data?.value || 0) + (data?.fee || 0)
    // total += servicefee
    setData(data)
    setCalculations({
      servicefee,
    })
  }, [])

  const refetch = () => {
    if (!location?.state?.data) history.replace('/digital')
    fetch(location?.state?.data || {}, token)
  }

  const calculateData = () => {
    const { payment, voucher } = forms
    const { servicefee } = calculations
    let total =
      data?.type?.id == -1 // recharge or package data
        ? data?.details?.current_price
        : data?.amount
    let subtotal = total
    let totalprice = total
    let discount = voucher?.discount_amount || 0
    let paymentfee = parseInt(payment?.admin_fee || 0)

    if (voucher?.discount_percentage) {
      let temp = (total * parseInt(voucher?.discount_percentage || 0)) / 100
      let max = parseInt(voucher?.maximum_discount || 0)
      discount = temp > max ? max : temp
    }

    totalprice += servicefee || 0
    totalprice -= discount
    totalprice += paymentfee || 0

    setCalculations({
      total: totalprice,
      subtotal,
      servicefee,
      paymentfee,
    })
  }

  useEffect(() => {
    calculateData()
  }, [data, forms])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <section className="content">
      <FetchHandler
        loading={state.loading}
        error={errordata}
        refetch={refetch}
        content={
          <Container>
            <Row>
              <Col xs="12" sm="8">
                <Left data={data} forms={forms} onChange={onChange} />
              </Col>
              <Col xs="12" sm="4">
                <Relative padding="0 0 0 35px" mobileStyle={{ padding: 0 }}>
                  <Right
                    data={data}
                    forms={forms}
                    setForms={setForms}
                    onChange={onChange}
                    calculations={calculations}
                  />
                </Relative>
              </Col>
            </Row>
          </Container>
        }
      />
    </section>
  )
}

export default DigitalCheckout
