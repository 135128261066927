import moment from 'moment'
import React from 'react'
import toast from 'react-hot-toast'
import { BiTime } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import { Image, Text } from '../../../../assets/components/components'
import { colors } from '../../../../assets/styles/styles'
import Button from '../../../../components/Button/Button'
import ModalFormTemplate from '../../../../components/Modal/ModalFormTemplate'
import api from '../api'

const ModalDetails = ({
  open,
  close,
  data,
  digital = false,
  apply,
  usable = true,
  details = {},
}) => {
  const dispatch = useDispatch()
  const activeStore = useSelector((state) => state.activeStore)
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, submit] = useAsyncFn(
    async (data, digital, details, activeStore, token) => {
      dispatch({ type: 'loading' })
      let cart_ids = []
      if (details?.cart && details?.cart.length > 0)
        details?.cart.map((g) => {
          cart_ids.push(g.cart_id)
          return g
        })

      let res = {}
      if (digital) {
        res = await api.useDigitalVoucher(
          data?.promo_code,
          data?.owner_id || '',
          details?.package_id || '',
          details?.recharge_id || '',
          details?.billing_id || '',
          token,
        )
      } else {
        res = await api.useVoucher(
          data?.promo_code,
          data?.owner_id || '',
          activeStore?.store_id,
          cart_ids,
          token,
        )
      }
      dispatch({ type: 'unloading' })
      if (!res.success) {
        if (res.forbidden) logout()
        toast.error(res.message)
        return false
      }
      apply(data)
      close()
    },
    [],
  )

  let end = moment(data?.end_period || data?.end_time, 'YYYY-MM-DD HH:mm:ss')
  let diff = end.diff(moment(), 'hours')

  return (
    <ModalFormTemplate
      open={open}
      close={close}
      title="Rincian Voucher"
      content={
        <>
          <Image
            src={data?.image || data?.picture}
            width="100%"
            height="180px"
            borderRadius="5px"
            objectFit="contain"
            margin="0 0 15px"
          />

          <Text fontSize="14px" color={diff > 24 ? '#aaa' : colors.primary}>
            <BiTime
              size={18}
              color={diff > 24 ? '#aaa' : colors.primary}
              style={{ marginRight: 5 }}
            />
            {diff > 24
              ? `Berlaku hingga ${moment(
                  data?.end_period || data?.end_time,
                  'YYYY-MM-DD HH:mm:ss',
                ).format('DD-MM-YYYY HH:mm')}`
              : `Berakhir dalam ${diff} jam`}
          </Text>

          <Text
            margin="10px 0 15px"
            dangerouslySetInnerHTML={{ __html: data?.description || '-' }}
          />

          {usable && (
            <Button
              color="primary"
              block
              onClick={() => submit(data, digital, details, activeStore, token)}
            >
              Gunakan Voucher
            </Button>
          )}
        </>
      }
    />
  )
}

export default ModalDetails
