import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import {
  Flex,
  Image,
  Relative,
  Text,
} from '../../../../../assets/components/components'
import { colors } from '../../../../../assets/styles/styles'
import Button from '../../../../../components/Button/Button'
import Input from '../../../../../components/Input/Input'
import FetchHandler from '../../../../../components/State/FetchHandler'
import Tabs from '../../../../../components/Tabs/Tabs'
import { compare } from '../../../../../utils/functions'
import api from '../../api'
import List from './List'

const RechargeBox = ({ forms, setForms, errors, onChange, submit }) => {
  const [fetched, setFetched] = useState(false)
  const [errordata, setErrordata] = useState(false)
  const [recharges, setRecharges] = useState([])
  const [packages, setPackages] = useState([])

  const [modalList, setModalList] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const activeAddress = useSelector((state) => state.activeAddress)
  const activeStore = useSelector((state) => state.activeStore)
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)
  const timeoutRef = useRef(null)

  const [state, check] = useAsyncFn(async (forms, token) => {
    setErrordata(false)
    let prefix = forms?.number.substring(0, 3)
    prefix = `+62${prefix}`
    const res = await api.checkProvider(prefix, token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let recharges = res.data.credits || []
    recharges = recharges.sort((g, h) => compare('nominal', 'asc', g, h))
    let packages = res.data.datapackages || []
    packages = packages.sort((g, h) => compare('nominal', 'asc', g, h))

    setRecharges(recharges)
    setPackages(packages)
    setFetched(true)
    setForms({ ...forms, numberData: res?.data || {} })
  }, [])

  useEffect(() => {
    if (timeoutRef?.current) clearTimeout(timeoutRef?.current)
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null

      if (forms?.number.length > 2 && !fetched) check(forms, token)
      else if (forms?.number.length < 3) {
        setFetched(false)
        setForms({ ...forms, numberData: '' })
      }
    }, 500)
  }, [forms.number])

  return (
    <>
      <Relative
        borderRadius="5px"
        padding="20px 20px 0"
        border={`1px solid ${colors.border}`}
      >
        <Text fontWeight="bold" fontSize="20px" margin="0 0 30px">
          Masukkan nomor Telepon
        </Text>

        <Input
          id="number"
          name="number"
          label="Nomor Telepon"
          value={forms.number}
          error={errors.number}
          onChange={onChange}
          valueType="phone"
          wrapperStyle={{ marginBottom: 30 }}
        />

        {forms?.numberData ? (
          <Flex margin="-25px 0 15px">
            <Image
              src={forms?.numberData?.icon}
              width="30px"
              objectFit="contain"
              margin="0 7px 0 0"
            />
            <Text>{forms?.numberData?.name || '-'}</Text>
          </Flex>
        ) : null}
      </Relative>

      {fetched ? (
        <FetchHandler
          loading={state.loading}
          error={errordata}
          refetch={() => check(forms, token)}
          errorText="Nomor Telepon Tidak Valid"
          content={
            <>
              <Relative
                borderRadius="5px"
                margin="15px 0 0"
                padding="20px"
                border={`1px solid ${colors.border}`}
              >
                <Tabs
                  list={[
                    {
                      key: 'recharge',
                      name: 'Pulsa',
                      content: (
                        <List
                          data={recharges}
                          forms={forms}
                          setForms={setForms}
                        />
                      ),
                    },
                    {
                      key: 'package',
                      name: 'Paket Data',
                      content: (
                        <List
                          data={packages}
                          forms={forms}
                          setForms={setForms}
                          type="package"
                        />
                      ),
                    },
                  ]}
                />
              </Relative>
            </>
          }
        />
      ) : null}

      <Button
        color="primary"
        block
        margin="20px 0 0"
        disabled={!forms?.recharge_or_package_details}
        onClick={() => submit(forms, token)}
      >
        Bayar Sekarang
      </Button>
    </>
  )
}

export default RechargeBox
