import React from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Flex, Text } from '../../assets/components/components'
import { colors } from '../../assets/styles/styles'

const SelectLooks = ({ label, value, placeholder, ...props }) => {
  return (
    <>
      {label && (
        <Text fontWeight="bold" fontSize="16px" margin="0 0 10px">
          {label}
        </Text>
      )}
      <Flex
        justifyContent="space-between"
        padding="12px 10px 12px 15px"
        borderRadius="5px"
        cursor="pointer"
        border={`1px solid ${colors.border}`}
        {...props}
      >
        <Text fontSize="15px">
          {value ? (
            <Text>{value}</Text>
          ) : (
            <Text opacity="0.6" fontSize="15px">
              {placeholder}
            </Text>
          )}
        </Text>

        <FiChevronDown size={20} color={colors.grey} />
      </Flex>
    </>
  )
}

export default SelectLooks
