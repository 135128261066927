import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import images from '../../../../assets/images'
import CardInfo from '../../../../components/View/CardInfo'
import { formatMoney } from '../../../../utils/functions'

const Pulsa = ({ data }) => {
  return (
    <>
      <CardInfo
        title={data?.details?.operator_data_package_id ? 'Paket Data' : 'Pulsa'}
        content={
          <>
            <Flex alignItems="flex-start">
              <FlexCell>
                <Image
                  source={images.icons.rechargeblue}
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FlexCell>
              <FlexCell padding="0 0 0 15px">
                {data?.details?.description ? (
                  <Text>{data?.details?.description}</Text>
                ) : null}
                <Text>{data?.number}</Text>
                <Text>{data?.numberData?.name}</Text>

                <Text>
                  {`Rp ${formatMoney(
                    data?.details?.nominal || data?.details?.current_price || 0,
                  )}`}
                </Text>
              </FlexCell>
            </Flex>
          </>
        }
      />
    </>
  )
}

export default Pulsa
