import React from 'react'
import { Flex, FlexCell, Text } from '../../../../assets/components/components'
import Copy from '../../../../components/Button/Copy'

const InfoList = ({
  margin = '0 0 15px',
  title,
  content,
  titleFlex = 0.45,
  contentFlex = 0.55,
  contentRight = true,
  withCopy = false,
}) => {
  return (
    <Flex justifyContent="space-between" margin={margin}>
      <FlexCell flex={titleFlex}>
        <Text fontWeight="bold">{title}</Text>
      </FlexCell>
      <FlexCell flex={contentFlex}>
        <Text textAlign={contentRight ? 'right' : 'left'}>{content}</Text>
        {withCopy && (
          <Text textAlign="right">
            <Copy message={content} />
          </Text>
        )}
      </FlexCell>
    </Flex>
  )
}

export default InfoList
