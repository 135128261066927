import React from 'react'
import { numberOnly, phoneNumberOnly } from '../../utils/functions'

const InputNormal = React.forwardRef(
  (
    {
      label,
      id,
      name,
      append,
      appendStyle,
      prepend,
      prependStyle,
      wrapperStyle,
      error,
      invalid,
      type = 'text',
      valueType = 'normal', // normal, phone, number
      onChange,
      style = {},
      value,
      className = 'form-control n-input',
      required,
      ...props
    },
    ref,
  ) => {
    if (valueType == 'phone') prepend = '+62'

    return (
      <div className="form-group" style={wrapperStyle}>
        {label && (
          <label htmlFor={id}>
            {label} {required && <span className="form-star-required">*</span>}
          </label>
        )}

        <div className="input-group">
          {prepend && (
            <div
              className="n-input-addition n-input-prepend"
              style={prependStyle}
            >
              {prepend}
            </div>
          )}

          <input
            {...props}
            ref={ref}
            type={type}
            id={id}
            name={name || id}
            value={value}
            onChange={(e) => {
              if (valueType == 'phone') {
                e.target.value = phoneNumberOnly(e.target.value)
              }
              if (valueType == 'number') {
                e.target.value = numberOnly(e.target.value)
              }
              onChange(e)
            }}
            className={className}
            style={{ paddingLeft: valueType == 'phone' ? 72 : 12, ...style }}
          />

          {append && (
            <div
              className="n-input-addition n-input-append"
              style={appendStyle}
            >
              {append}
            </div>
          )}
        </div>

        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    )
  },
)

export default InputNormal
