import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost } from '../../../utils/fetch'

export default {
  async getMyVoucher(scope = '', token) {
    let responseJSON = await fetchGet(
      baseURL + `/voucher/myvoucher?voucher_scope=${scope}`,
      token,
    )
    return responseJSON
  },

  async getOnlineVoucher(search, token) {
    let form = JSON.stringify({
      search,
    })
    let responseJSON = await fetchPost(baseURL + '/onlinevoucher', form, token)
    return responseJSON
  },

  async getOfflineVoucher(search, token) {
    let form = JSON.stringify({
      search,
    })
    let responseJSON = await fetchPost(baseURL + '/offlinevoucher', form, token)
    return responseJSON
  },

  async useVoucher(promo_code, owner_id, store_id, cart_ids, token) {
    let form = JSON.stringify({
      promo_code,
      owner_id,
      store_id,
      cart_ids,
    })
    let responseJSON = await fetchPost(baseURL + '/voucher/check', form, token)
    return responseJSON
  },

  async useDigitalVoucher(
    promo_code,
    owner_id,
    operator_data_package_id,
    operator_credit_package_id,
    billing_log_id,
    token,
  ) {
    let form = JSON.stringify({
      promo_code,
      owner_id,
      operator_data_package_id,
      operator_credit_package_id,
      billing_log_id,
    })
    let responseJSON = await fetchPost(
      baseURL + '/voucher/digital/check',
      form,
      token,
    )
    return responseJSON
  },
}
