import React from 'react'
import MediaQuery from 'react-responsive'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../assets/components/components'
import images from '../../../assets/images'
import { colors } from '../../../assets/styles/styles'
import { FiLogOut } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

export const menus = [
  {
    name: 'Akun',
    icon: images.icons.profile,
    to: '/account',
    desktop: true,
    mobile: false,
  },
  {
    name: 'Notifikasi',
    icon: images.icons.notification,
    to: '/category',
    desktop: false,
    mobile: true,
    notification: true,
  },
  {
    name: 'Kategori',
    icon: images.icons.category,
    to: '/category',
    desktop: false,
    mobile: true,
    category: true,
  },
  {
    name: 'Transaksi Saya',
    icon: images.icons.shop,
    to: '/account/transactions',
    desktop: true,
    mobile: true,
  },
  {
    name: 'Transaksi Digital',
    icon: images.icons.shop,
    to: '/account/digital/transactions',
    desktop: true,
    mobile: true,
  },
  {
    name: 'Produk Disukai',
    icon: images.icons.heart,
    to: '/account/favorite',
    desktop: true,
    mobile: true,
  },
  {
    name: 'Daftar Alamat Saya',
    icon: images.icons.address,
    to: '/account/address',
    desktop: true,
    mobile: true,
  },
  {
    name: 'Pengaturan',
    icon: images.icons.settings,
    to: '/account/settings',
    desktop: true,
    mobile: true,
  },
  {
    name: 'Keluar',
    iconFix: (
      <FiLogOut style={{ marginLeft: 2 }} size={22} color={colors.primary} />
    ),
    desktop: true,
    mobile: true,
  },
]

const Menu = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <MediaQuery minWidth={768}>
      <ul className="clear-ul">
        {menus
          ?.filter((g) => g.desktop)
          .map((g, i) => {
            const active = g.to == window.location.pathname
            return (
              <li key={i}>
                <Flex
                  margin="0 0 25px"
                  cursor="pointer"
                  onClick={() => {
                    if (g.name === 'Keluar') {
                      dispatch({ type: 'logout' })
                      window.location.href = '/'
                      return
                    }
                    history.push(g.to)
                  }}
                >
                  <FlexCell padding="0 15px 0 0">
                    {g.iconFix ? (
                      g.iconFix
                    ) : (
                      <Image
                        src={g.icon}
                        objectFit="contain"
                        width="25px"
                        height="25px"
                        alt={g.name}
                      />
                    )}
                  </FlexCell>
                  <FlexCell>
                    <Text
                      color={active ? colors.primary : colors.black}
                      fontWeight={active ? 'bold' : '400'}
                    >
                      {g.name}
                    </Text>
                  </FlexCell>
                </Flex>
              </li>
            )
          })}
      </ul>
    </MediaQuery>
  )
}

export default Menu
