import BPJS from './BPJS'
import Garuda from './Garuda'
import MNCPlay from './MNCPlay'
import MNCVision from './MNCVision'
import PLNPostpaid from './PLNPostpaid'
import PLNPrepaid from './PLNPrepaid'
import Pulsa from './Pulsa'
import TelkomGroup from './TelkomGroup'
import TelkomHalo from './TelkomHalo'

export default {
  '010001': TelkomHalo,
  '070007': PLNPostpaid,
  '070002': PLNPrepaid,
  '001001': TelkomGroup,
  '080003': BPJS,
  '030001': Garuda,
  '090001': MNCVision,
  '060100': MNCPlay,
  0: TelkomHalo,
  '-1': Pulsa,
}
