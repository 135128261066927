import figureaddress from './figure/figure-address.png'
import figurebill from './figure/figure-bill.png'
import figurecartempty from './figure/figure-cart-empty.png'
import figurecart from './figure/figure-cart.png'
import figurecod from './figure/figure-cod.png'
import figurecs from './figure/figure-cs.png'
import figurelocationwhite from './figure/figure-location-white.png'
import figurelocation from './figure/figure-location.png'
import figurelogin from './figure/figure-login.png'
import figurelogout from './figure/figure-logout.png'
import figurenovoucher from './figure/figure-no-voucher.png'
import figurenostore from './figure/figure-nostore.png'
import figurepreorder from './figure/figure-preorder.png'
import figurepulsa from './figure/figure-pulsa.png'
import figureregister from './figure/figure-register.png'
import figuresplash from './figure/figure-splash.png'
import figuretoken from './figure/figure-token.png'
import figureflashsale from './figure/flashsale.png'
import figurepaymentart from './figure/payment-art.jpg'
import iconaccountactive from './icons/icon-account-active.png'
import iconaccount from './icons/icon-account.png'
import iconaddress from './icons/icon-address.png'
import iconbilling from './icons/icon-billing.png'
import iconbox from './icons/icon-box.png'
import iconcartactive from './icons/icon-cart-active.png'
import iconcartoutline from './icons/icon-cart-outline.png'
import iconcart from './icons/icon-cart.png'
import iconcategory from './icons/icon-category.png'
import iconcrosshair from './icons/icon-crosshair.png'
import icondelivery from './icons/icon-delivery.png'
import icondigitalactive from './icons/icon-digital-active.png'
import icondigitalproduct from './icons/icon-digital-product.png'
import icondigital from './icons/icon-digital.png'
import iconelectric from './icons/icon-electric.png'
import iconflashsalesmall from './icons/icon-flashsale-small.png'
import iconflashsale from './icons/icon-flashsale.png'
import icongift from './icons/icon-gift.png'
import icongoogle from './icons/icon-google.png'
import iconheart from './icons/icon-heart.png'
import iconhomeactive from './icons/icon-home-active.png'
import iconhomemenu from './icons/icon-home-menu.png'
import iconhome from './icons/icon-home.png'
import iconmarker from './icons/icon-marker.png'
import iconmin from './icons/icon-min.png'
import iconnotification from './icons/icon-notification.png'
import iconpasswordred from './icons/icon-password-red.png'
import iconpasswordwhite from './icons/icon-password-white.png'
import iconplus from './icons/icon-plus.png'
import iconprofile from './icons/icon-profile.png'
import iconrechargeblue from './icons/icon-recharge-blue.png'
import iconrecharge from './icons/icon-recharge.png'
import iconsettings from './icons/icon-settings.png'
import iconshield from './icons/icon-shield.png'
import iconshop from './icons/icon-shop.png'
import iconsoldout from './icons/icon-soldout.png'
import iconsuccesswhite from './icons/icon-success-white.png'
import iconsuccess from './icons/icon-success.png'
import iconterms from './icons/icon-terms.png'
import icontransactionactive from './icons/icon-transaction-active.png'
import icontransaction from './icons/icon-transaction.png'
import icontrashwhite from './icons/icon-trash-white.png'
import iconvoucher from './icons/icon-voucher.png'
import logoalfacare from './logo/logo-alfacare.png'
import logomidikriing from './logo/logo-midikriing.png'
import logomidikriing2 from './logo/logo-midikriing2.png'
import logoreminder from './logo/logo-reminder.png'
import logovertical from './logo/logo-vertical.png'
import logohorizontal from './logo/logo.png'
import facebook from './social/facebook.png'
import instagram from './social/instagram.png'
import twitter from './social/twitter.png'
import youtube from './social/youtube.png'

export default {
  figure: {
    address: figureaddress,
    bill: figurebill,
    cart: figurecart,
    cod: figurecod,
    cs: figurecs,
    locationwhite: figurelocationwhite,
    location: figurelocation,
    logout: figurelogout,
    novoucher: figurenovoucher,
    nostore: figurenostore,
    preorder: figurepreorder,
    pulsa: figurepulsa,
    register: figureregister,
    splash: figuresplash,
    token: figuretoken,
    login: figurelogin,
    flashsale: figureflashsale,
    cartempty: figurecartempty,
    paymentart: figurepaymentart,
  },
  logo: {
    horizontal: logohorizontal,
    vertical: logovertical,
    alfacare: logoalfacare,
    midikriing: logomidikriing,
    midikriing2: logomidikriing2,
    reminder: logoreminder,
  },
  icons: {
    plus: iconplus,
    min: iconmin,
    google: icongoogle,
    category: iconcategory,
    homemenu: iconhomemenu,
    home: iconhome,
    homeactive: iconhomeactive,
    cart: iconcart,
    cartactive: iconcartactive,
    transaction: icontransaction,
    transactionactive: icontransactionactive,
    digital: icondigital,
    digitalactive: icondigitalactive,
    account: iconaccount,
    accountactive: iconaccountactive,
    success: iconsuccess,
    successwhite: iconsuccesswhite,
    voucher: iconvoucher,
    passwordred: iconpasswordred,
    passwordwhite: iconpasswordwhite,
    crosshair: iconcrosshair,
    electric: iconelectric,
    recharge: iconrecharge,
    rechargeblue: iconrechargeblue,
    billing: iconbilling,
    gift: icongift,
    trashwhite: icontrashwhite,
    flashsale: iconflashsale,
    flashsalesmall: iconflashsalesmall,
    soldout: iconsoldout,
    address: iconaddress,
    cartoutline: iconcartoutline,
    delivery: icondelivery,
    heart: iconheart,
    marker: iconmarker,
    profile: iconprofile,
    settings: iconsettings,
    shop: iconshop,
    notification: iconnotification,
    digitalproduct: icondigitalproduct,
    shield: iconshield,
    terms: iconterms,
    box: iconbox,
  },
  social: {
    instagram,
    facebook,
    twitter,
    youtube,
  },
}
