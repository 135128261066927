import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'

const BPJS = ({ data }) => {
  return (
    <>
      <CardInfo
        title={data?.type?.name}
        content={
          <>
            <Flex>
              <FlexCell>
                <Image
                  source={data?.type?.picture}
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FlexCell>
              <FlexCell padding="0 0 0 15px">
                <Text fontWeight="bold">
                  {data?.details?.customerData?.billInfo1?.customerName} (
                  {parseInt(data?.details?.customerData?.memberTotal || 0)}{' '}
                  Jiwa)
                </Text>
                <Text>{data?.number}</Text>
                <Text>
                  {parseInt(data?.details?.customerData?.numberOfMonth || 0)}{' '}
                  Bulan
                </Text>
              </FlexCell>
            </Flex>
          </>
        }
      />
    </>
  )
}

export default BPJS
