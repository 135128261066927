import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async getHomepage(store_id, token) {
    let form = JSON.stringify({
      store_id,
      is_web_platform: 1,
    })
    let responseJSON = await fetchPost(baseURL + '/homepage', form, token)
    return responseJSON
  },

  async getProductCategory(category = '', token) {
    let form = JSON.stringify({
      category,
    })
    let responseJSON = await fetchPost(baseURL + '/category', form, token)
    return responseJSON
  },
}
