import { baseURL } from '../../../utils/apihost'
import { fetchPost } from '../../../utils/fetch'

export default {
  async getTransactionDetails(transaction_id, token) {
    let form = JSON.stringify({ transaction_id })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/detail',
      form,
      token,
    )
    return responseJSON
  },

  async receiveTransaction(TransactionId, token) {
    let form = JSON.stringify({ TransactionId })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/receive',
      form,
      token,
    )
    return responseJSON
  },

  async rateTransaction(TransactionId, Rate, token) {
    let form = JSON.stringify({ TransactionId, Rate })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/rate',
      form,
      token,
    )
    return responseJSON
  },

  async reviewProduct(TransactionDetailId, Rate, Review, token) {
    let form = JSON.stringify({ TransactionDetailId, Rate, Review })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/reviewproduct',
      form,
      token,
    )
    return responseJSON
  },

  async trackTransaction(TransactionId, token) {
    let form = JSON.stringify({ TransactionId })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/tracking',
      form,
      token,
    )
    return responseJSON
  },

  async snapToken(TransactionId, digital_transaction_id, token) {
    let form = JSON.stringify({ TransactionId, digital_transaction_id })
    let responseJSON = await fetchPost(
      baseURL + '/transaction/snaptoken',
      form,
      token,
    )
    return responseJSON
  },
}
