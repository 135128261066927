import moment from 'moment'
import React from 'react'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../assets/components/components'
import CardInfo from '../../../../components/View/CardInfo'
import InfoList from '../../../../components/View/InfoList'

const PLNPostpaid = ({ data }) => {
  let period = '-'
  if (data?.details?.customerData?.billInfo?.length > 0) {
    let first = moment(
      parseInt(data?.details?.customerData?.billInfo[0]?.period).toString(),
      'YYYYMM',
    ).format('MMMYY')
    let last = moment(
      parseInt(
        data?.details?.customerData?.billInfo[
          data?.details?.customerData?.billInfo?.length - 1
        ]?.period,
      ).toString(),
      'YYYYMM',
    ).format('MMMYY')
    period = first + ' - ' + last
  }

  return (
    <>
      <CardInfo
        title={data?.type?.name}
        content={
          <>
            <Flex>
              <FlexCell>
                <Image
                  source={data?.type?.picture}
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FlexCell>
              <FlexCell padding="0 0 0 15px">
                <Text fontWeight="bold">{data?.customer_name}</Text>
                <Text>{data?.number}</Text>
              </FlexCell>
            </Flex>
          </>
        }
        margin
      />

      <CardInfo
        title={'Detail Tagihan'}
        content={
          <>
            <InfoList
              title="Tarif / Daya"
              content={`${data?.details?.customerData?.power} VA`}
              contentRight={false}
              titleFlex={0.4}
              contentFlex={0.6}
            />
            <InfoList
              title="Bulan / Tahun"
              content={period}
              contentRight={false}
              titleFlex={0.4}
              contentFlex={0.6}
            />
            <InfoList
              title="Stand Meter"
              content={`${
                data?.details?.customerData?.billInfo[
                  data?.details?.customerData?.billInfo?.length - 1
                ]?.presentMeter
              }`}
              contentRight={false}
              titleFlex={0.4}
              contentFlex={0.6}
            />
          </>
        }
      />
    </>
  )
}

export default PLNPostpaid
