import React from 'react'
import { Flex, Image } from '../../assets/components/components'
import { colors } from '../../assets/styles/styles'

const IconWrapper = ({
  size = '50px',
  iconSize = '25px',
  icon,
  renderIcon,
}) => {
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        width={size}
        height={size}
        backgroundColor={colors.primary10}
      >
        {renderIcon || <Image src={icon} width={iconSize} />}
      </Flex>
    </>
  )
}

export default IconWrapper
