import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import Button from '../../../components/Button/Button'
import { getParameterByName } from '../../../utils/functions'
import master from '../../../utils/master'
import apiPayment from '../../Cart/Payment/api'
import Menu from '../components/Menu'
import api from './api'
import Data from './components/Data'

const DigitalTransactionDetails = ({ match }) => {
  const [errordata, setErrordata] = useState(false)
  const [summary, setSummary] = useState({
    status: 1,
    // datestart: moment(),
    // dateend: moment(),
    limit: 20,
    current_page: 1,
    total_data: 0,
    total_page: 1,
  })
  const [details, setDetails] = useState('')
  const [log, setLog] = useState({})
  const [inqlog, setInqLog] = useState({})
  const [checkedall, setCheckedall] = useState(true)
  const [activeData, setActiveData] = useState('')

  const [modalPay, setModalPay] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const activeStore = useSelector((state) => state.activeStore)
  const threshold = useSelector((state) => state.threshold)
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, fetch] = useAsyncFn(async (id, token) => {
    setErrordata(false)
    const res = await api.getTransactionDetails(id, token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let data = res.data?.transaction || {}
    let voucher = res.data?.voucher || ''
    let log = data?.billing_log?.response || ''
    let inqlog = data?.inquiry_log?.response || ''
    let payment = res?.data?.payment || ''
    if (log) {
      log = await JSON.parse(log)
    }
    if (inqlog) {
      inqlog = await JSON.parse(inqlog)
    }
    data = { ...data, voucher, payment }
    setDetails(data)
    setLog(log)
    setInqLog(inqlog)

    let showPayment = getParameterByName('pay')
    if (showPayment) {
      history.replace(
        '/account/digital/transactions/details/' + match?.params?.id,
      )
      redirectPayment(data)
    }
  }, [])

  const refetch = () => {
    fetch(match?.params?.id, token)
  }

  const redirectPayment = async (data) => {
    if (!data?.payment) {
      dispatch({ type: 'loading' })
      const res = await apiPayment.createPayment(
        '',
        data?.digital_transaction_id,
        data?.token_id || '',
        token,
      )
      dispatch({ type: 'unloading' })
      if (!res.success) {
        if (res.forbidden) logout()
        toast.error(
          master.paymentMap[data?.payment_method_id]?.type == 'cc'
            ? 'Kartu kredit tidak valid, mohon coba kembali'
            : 'Pembayaran gagal, mohon coba kembali',
        )
        return false
      }
    }
    history.push('/payment/digital/' + match?.params?.id)
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <section className="content">
      <Container>
        <Row>
          <Col xs="12" sm="3">
            <Menu />
          </Col>

          <Col xs="12" sm="9">
            <Data
              details={details}
              log={log}
              inqlog={inqlog}
              loading={state.loading}
              error={errordata}
              refetch={refetch}
            />

            {details?.status == 0 && (
              <Row>
                <Col xs="12" sm="8" />
                <Col xs="12" sm="4">
                  <Button
                    color="primary"
                    block
                    margin="20px 0 0"
                    onClick={() => redirectPayment(details)}
                  >
                    Bayar Sekarang
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default DigitalTransactionDetails
