import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import { Text } from '../../../assets/components/components'
import FetchHandler from '../../../components/State/FetchHandler'
import apiDetails from '../../Account/TransactionDetails/api'
import PaymentDetails from './components/PaymentDetails'
import Total from './components/Total'

const Payment = ({ location, match }) => {
  const [loading, setLoading] = useState(true)
  const [errordata, setErrordata] = useState(false)
  const [data, setData] = useState('')

  const history = useHistory()
  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, fetch] = useAsyncFn(async (id, token) => {
    setLoading(true)
    setErrordata(false)
    const res = await apiDetails.getTransactionDetails(id, token)
    setLoading(false)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let data = res.data?.transaction || {}
    data = {
      ...data,
      deliveries: res?.data?.deliveries || [],
      payment: res.data?.payment || '',
    }
    setData(data)
    if (data?.payment?.status == 1) {
      history.goBack()
      return false
    }
  }, [])

  const refetch = () => {
    if (!match?.params?.id) history.goBack()
    fetch(match?.params?.id, token)
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <section className="content">
      <FetchHandler
        loading={loading}
        error={errordata}
        refetch={refetch}
        content={
          <Container>
            <Row>
              <Col xs="12" sm="12">
                <Text fontWeight="bold" fontSize="17px" margin="0 0 20px">
                  Pembayaran
                </Text>

                <Total data={data} />

                <PaymentDetails data={data} refetch={refetch} />
              </Col>
            </Row>
          </Container>
        }
      />
    </section>
  )
}

export default Payment
