import moment from 'moment'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { getParameterByName, hash_sha256 } from '../../../utils/functions'
import api from './api'

const inputs = {
  phone: '',
  password: '',
}

const errs = {
  phone: '',
  password: '',
}

const PasswordlessLogin = ({ location }) => {
  const [forms, setForms] = useState({ ...inputs })
  const [errors, setErrors] = useState({ ...errs })
  const [fetching, setFetching] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const [state, submit] = useAsyncFn(async (phone_number) => {
    dispatch({ type: 'loading' })
    let phone = phone_number.substring(1)
    phone = '+' + phone
    let res = await api.passwordlessLogin(
      phone,
      hash_sha256(
        `${phone}_${moment().format(
          'YYMMDD',
        )}_c8c52c6ec9dad397a5fb5cade2d0aef6`,
      ),
    )

    dispatch({ type: 'unloading' })
    if (!res.success) {
      if (res.data?.redirectToTokenPage) {
        history.push('/register/otp', {
          customer_id: res.data?.customer_id,
          send: true,
        })
        return false
      }
      toast.error(res.message)
      return false
    }
    redirect(res?.data)
  }, [])

  const redirect = (data) => {
    let redirect = getParameterByName('redirect') || ''
    dispatch({
      type: 'login',
      user: data?.customer?.data || {},
      token: data?.token || '',
    })
    history.replace(redirect)
  }

  useEffect(() => {
    let phone_number = getParameterByName('ph') || ''
    let redirect = getParameterByName('redirect') || ''
    if (phone_number && redirect) submit(phone_number)
    else history.replace('/')
  }, [])

  return null
}

export default PasswordlessLogin
