import React from 'react'
import { Col, Row } from 'reactstrap'
import { Relative, Text } from '../../../../assets/components/components'
import { colors } from '../../../../assets/styles/styles'
import FetchHandler from '../../../../components/State/FetchHandler'
import { formatMoney } from '../../../../utils/functions'
import Statuses from '../../DigitalTransactions/components/Statuses'
import Types from '../OrderType/index'
import Pulsa from '../OrderType/Pulsa'

const Data = ({ details, log, inqlog, loading, error, refetch }) => {
  let OrderType = Types[log?.productCode || 0]

  return (
    <>
      <FetchHandler
        loading={loading}
        error={error}
        refetch={refetch}
        content={
          <>
            <Row>
              <Col xs="12" sm="6">
                <Text fontSize="13px">No Pesanan</Text>
                <Text
                  fontWeight="bold"
                  fontSize="14px"
                  mobileStyle={{ marginBottom: 10 }}
                >
                  {details?.transaction_number || '-'}
                </Text>
              </Col>
              <Col xs="12" sm="4">
                <Text fontSize="13px">Status</Text>
                <Statuses status={details?.status} textAlign="left" />
              </Col>
            </Row>

            <Relative margin="20px 0 0" />

            {log ? (
              <OrderType data={details} log={log} inqlog={inqlog} />
            ) : (
              <Pulsa data={details} />
            )}

            <Relative
              margin="20px 0 0"
              padding="12px 15px"
              borderRadius="5px"
              border={`1px solid ${colors.border}`}
            >
              <Row>
                <Col xs="6" sm="6">
                  <Text fontSize="14px">Total Belanja</Text>
                </Col>
                <Col xs="6" sm="6">
                  <Text fontWeight="bold" textAlign="right">
                    Rp {formatMoney(details?.total_price)}
                  </Text>
                </Col>
              </Row>
            </Relative>
          </>
        }
      />
    </>
  )
}

export default Data
