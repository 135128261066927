import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import { Col, Container, Row } from 'reactstrap'
import Menu from '../components/Menu'
import api from './api'
import Data from './components/Data'

const DigitalTransactions = ({ match }) => {
  const [errordata, setErrordata] = useState(false)
  const [summary, setSummary] = useState({
    status: 0,
    // datestart: moment(),
    // dateend: moment(),
    limit: 20,
    current_page: 1,
    total_data: 0,
    total_page: 1,
  })
  const [data, setData] = useState([])
  const [checkedall, setCheckedall] = useState(true)
  const [activeData, setActiveData] = useState('')

  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, fetch] = useAsyncFn(async (query, token) => {
    setErrordata(false)
    const res = await api.getTransaction('', -1, token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let data = res.data || []
    setData(data)
  }, [])

  const refetch = (query = {}) => {
    fetch({ ...summary, ...query }, token)
  }

  useEffect(() => {
    refetch()
  }, [])
  return (
    <section className="content">
      <Container>
        <Row>
          <Col xs="12" sm="3">
            <Menu />
          </Col>

          <Col xs="12" sm="9">
            <Data
              data={data}
              loading={state.loading}
              error={errordata}
              refetch={refetch}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default DigitalTransactions
