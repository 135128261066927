import moment from 'moment'
import React from 'react'
import { BiTime } from 'react-icons/bi'
import {
  Flex,
  FlexCell,
  Image,
  Relative,
  Text,
} from '../../../../assets/components/components'
import { colors } from '../../../../assets/styles/styles'

const List = ({ data, online = true, onClick, openDetails }) => {
  let end = moment(
    online ? data?.end_period : data?.end_time,
    'YYYY-MM-DD HH:mm:ss',
  )
  let diff = end.diff(moment(), 'hours')

  return (
    <>
      <Relative
        padding="15px"
        margin="20px 0 0"
        borderRadius="5px"
        cursor="pointer"
        border={`1px solid ${colors.border}`}
        onClick={onClick}
      >
        <Image
          src={data?.image || data?.picture}
          width="100%"
          height="180px"
          borderRadius="5px"
          objectFit="contain"
        />

        <Flex justifyContent="space-between" margin="15px 0 0">
          <FlexCell>
            <Text fontSize="14px" color={diff > 24 ? '#aaa' : colors.primary}>
              <BiTime
                size={18}
                color={diff > 24 ? '#aaa' : colors.primary}
                style={{ marginRight: 5 }}
              />
              {diff > 24
                ? `Berlaku hingga ${moment(
                    online ? data?.end_period : data?.end_time,
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('DD-MM-YYYY HH:mm')}`
                : `Berakhir dalam ${diff} jam`}
            </Text>
          </FlexCell>
          <FlexCell>
            <Text
              fontSize="14px"
              fontWeight="bold"
              cursor="pointer"
              color={colors.primary}
              // onClick={openDetails}
            >
              Rincian
            </Text>
          </FlexCell>
        </Flex>
      </Relative>
    </>
  )
}

export default List
