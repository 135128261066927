import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import {
  Flex,
  FlexCell,
  Image,
  Text,
} from '../../../../../assets/components/components'
import images from '../../../../../assets/images'
import ModalFormTemplate from '../../../../../components/Modal/ModalFormTemplate'
import FetchHandler from '../../../../../components/State/FetchHandler'
import ModalList from '../../../../../components/View/ModalList'
import { formatMoney } from '../../../../../utils/functions'
import api from '../../api'

const ModalPaymentMethod = ({
  open,
  close,
  forms,
  onChange,
  calculations,
  type = 0,
  useCOD = true,
}) => {
  const [errordata, setErrordata] = useState(false)
  const [data, setData] = useState([])

  const token = useSelector((state) => state.token)
  const logout = useSelector((state) => state.logout)

  const [state, fetch] = useAsyncFn(async (total, type, useCOD, token) => {
    setErrordata(false)
    const res = await api.getPaymentMethods(total, type, token)
    if (!res.success) {
      if (res.forbidden) logout()
      setErrordata(true)
      return false
    }

    let data = res.data?.method || []
    if (useCOD)
      data.unshift({
        logo: images.icons.box,
        payment_method_id: 'cod',
        name: 'Cash on Delivery (COD)',
        desc: 'Bayar dengan cash saat produk diantarkan kurir',
        cod: true,
      })
    setData(data)
  }, [])

  const refetch = () => {
    fetch(calculations?.total || 0, type, useCOD, token)
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <ModalFormTemplate
      open={open}
      close={close}
      title="Pilih Metode Pembayaran"
      content={
        <>
          <FetchHandler
            loading={state.loading}
            error={errordata}
            refetch={refetch}
            content={
              <>
                {data?.map((g, i) => {
                  const active =
                    g.payment_method_id == forms?.courier?.payment_method_id
                  let disabled = false
                  return (
                    <ModalList
                      key={i}
                      active={active}
                      disabled={disabled}
                      onClick={() => {
                        onChange({ name: 'payment', value: g })
                        close()
                      }}
                    >
                      <Flex>
                        <FlexCell>
                          <Flex justifyContent="center" width="50px">
                            <Image
                              src={g.logo}
                              width={g.cod ? '40px' : '50px'}
                              height="auto"
                              objectFit="contain"
                            />
                          </Flex>
                        </FlexCell>
                        <FlexCell padding="0 0 0 15px">
                          <Text fontWeight="bold">{g.name || '-'}</Text>
                          {g.cod ? (
                            <Text fontSize="14px">{g.desc || '-'}</Text>
                          ) : (
                            <Text fontSize="14px">
                              Biaya Admin{' '}
                              {g.admin_fee == 0
                                ? 'Gratis'
                                : `Rp${formatMoney(g.admin_fee || 0)}`}
                            </Text>
                          )}
                        </FlexCell>
                      </Flex>
                    </ModalList>
                  )
                })}
              </>
            }
          />
        </>
      }
    />
  )
}

export default ModalPaymentMethod
