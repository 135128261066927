import React from 'react'
import { Text } from '../../../../assets/components/components'
import { colors } from '../../../../assets/styles/styles'

export const statuses = {
  0: {
    color: colors.red,
    text: 'Menunggu Pembayaran',
    details: 'Menunggu Pembayaran',
  },
  1: {
    color: colors.secondary,
    text: 'Dalam Proses',
    details: 'Transaksi Dalam Proses',
  },
  2: {
    color: '#999',
    text: 'Transaksi Selesai',
    details: 'Transaksi Selesai',
  },
  3: {
    color: colors.red,
    text: 'Transaksi Gagal, Klik Untuk Detail',
    details: 'Transaksi Gagal',
  },
}

const Statuses = ({ status, textAlign = 'right' }) => {
  return (
    <Text
      color={statuses[status]?.color}
      textAlign={textAlign}
      fontSize="14px"
      margin="0 0 3px"
    >
      {statuses[status]?.text}
    </Text>
  )
}

export default Statuses
