import { baseURL } from '../../../utils/apihost'
import { fetchGet } from '../../../utils/fetch'

export default {
  async getTransaction(status, sort = '', token) {
    let responseJSON = await fetchGet(
      baseURL + `/digital/get?status=${status}&sort_by_time=${sort}`,
      token,
    )
    return responseJSON
  },
}
