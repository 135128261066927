import { createStore } from 'redux'

const initialState = {
  token: '',
  user: {},
  ready: false,
  authenticated: false,
  loading: false,

  success: {
    open: false,
    message: '',
    icon: '',
    buttonOnPress: () => {},
  },

  currentLocation: {
    latitude: -6.17543,
    longitude: 106.827083,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  },

  threshold: 12,
  store: [],
  address: [],
  cart: [],
  categories: [],
  billers: [],

  activeAddress: {},
  activeStore: {},

  resetCart: false,
  refetchCart: false,
  refetchAddress: false,
  refetchStores: false,

  productDetails: '',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'update':
      return { ...state, ...rest }

    case 'login':
      localStorage.setItem(
        'login_data',
        JSON.stringify({ user: rest?.user, token: rest?.token }),
      )

      return {
        ...state,
        user: rest?.user,
        token: rest?.token,
      }

    case 'logout':
      localStorage.clear()
      return initialState

    case 'loading':
      return {
        ...state,
        loading: true,
      }

    case 'unloading':
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

const store = createStore(changeState)

export default store
