import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost } from '../../../utils/fetch'

export default {
  async getProfile(token) {
    let responseJSON = await fetchGet(baseURL + '/profile', token)
    return responseJSON
  },

  async getMidiPoint(token) {
    let responseJSON = await fetchGet(baseURL + '/getmidipoint', token)
    return responseJSON
  },

  async updateProfile(
    nama,
    phone_number,
    avatar,
    gender,
    email,
    birth_date,
    token,
  ) {
    let FormData = require('form-data')
    let form = new FormData()
    form.append('nama', nama)
    form.append('phone_number', phone_number)
    form.append('gender', gender)
    form.append('email', email)
    form.append('avatar', avatar)
    if (birth_date) form.append('birth_date', birth_date)
    let responseJSON = await fetchPost(
      baseURL + '/updateProfile',
      form,
      token,
      false,
      true,
    )
    return responseJSON
  },

  async updatePonta(ponta_number, token) {
    let form = JSON.stringify({
      ponta_number,
    })
    let responseJSON = await fetchPost(
      baseURL + '/updatepontanumber',
      form,
      token,
    )
    return responseJSON
  },
}
