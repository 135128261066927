let env = 'production'
let prefix = 'pro'
let midtransENV = 'production'

const developments = {
  'localhost:3000': true,
  'localhost:3001': true,
  'web.alfamidikring.com/': true,
}

if (developments[window.location.host]) {
  env = 'staging'
  prefix = 'pro'
  midtransENV = 'sandbox'
}

let baseURL = `https://${prefix}.alfamidikring.com/api`
let host = `https://${window.location.host}`
let midtransURL =
  'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js'

export { env, baseURL, host, midtransENV, midtransURL }
